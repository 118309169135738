import { Link } from "@mui/material";
import React from "react";

import { User } from "src/shared/api";
import { routesMap } from "src/shared/config";
import { crmRoutesMap } from "src/shared/config";

interface Props {
  user: User;
}

export const UserPreview: React.FunctionComponent<Props> = ({ user }) => {
  return (
    <div>
      <Link href={crmRoutesMap.user.getRoute(user.id)}>
        {user.full_name || user.email || user.phone}
      </Link>
    </div>
  );
};
