import {
  ListRequestVariables,
  RequestHookParams,
  ResponseWithMeta,
  Review,
  request,
  useRequest,
} from "src/shared/api";
import { apiRoutesMap } from "src/shared/config";

export const useGetRewiews = ({
  variables = {},
  ...params
}: RequestHookParams<ListRequestVariables<{ products?: string | null }>>) =>
  useRequest<ResponseWithMeta<Review[]>>(
    apiRoutesMap.reviews.getRoute(variables)
  );

export const changeReview = ({
  id,
  ...data
}: {
  id: number;
  enabled: boolean;
}) => request.patch(apiRoutesMap.review.getRoute({ id }), data);
