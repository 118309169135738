import React, { useState } from "react";
import {
  CharacteristcEdit,
} from "src/components/common";
import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import { useGetProduct } from "src/services/apiService";

//entities
import { ProductPreview } from "src/entities/product";

//shared
import { Product, ProductType } from "src/shared/api";


interface Props {
  product: Product;
  productType?: ProductType | null;
  index: number;
  count: number;
  refetchType?: () => any;
}

export const ProductPreviewWithEdit: React.FunctionComponent<Props> = ({
  product,
  productType,
  count,
  index,
  refetchType,
}) => {
  const [skip, setSkip] = useState(true);
  const requset = useGetProduct({ variables: { id: product.id }, skip });

  const handleSave = () => {
    setSkip(false);
    requset.refetch();
  };

  const data = requset.data || product;

  return (
    <Grid container>
      <Grid item xs={12} md={5}>
        <ProductPreview
          product={data}
          disableTabIndex
          onSave={handleSave}
          loading={requset.isLoading}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Box display="flex" alignItems="center">
          {productType?.attributes
            ?.sort((a, b) => (a.name > b.name ? 1 : -1))
            ?.map((attribute, attributeIndex) => (
              <Box ml={1} key={attribute.id}>
                <CharacteristcEdit
                  tabIndex={
                    (index as number) +
                    1 +
                    (count as number) * (attributeIndex + 1)
                  }
                  onSave={handleSave}
                  onAddValue={refetchType}
                  product={data}
                  attribute={attribute}
                  characteristc={data?.characteristics?.find(
                    (characteristic) =>
                      characteristic?.attribute?.id === attribute.id
                  )}
                />
              </Box>
            ))}
        </Box>
      </Grid>
    </Grid>
  );
};
