import { Google, LinkOutlined } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { blue, red } from "@mui/material/colors";
import React from "react";
import { routesMap } from "src/routes";

//shared
import { Product } from "src/shared/api";

interface Props {
  product: Product;
  tabIndex?: number;
}

export const ProductLinks: React.FunctionComponent<Props> = ({
  product,
  tabIndex = 0,
}) => {
  return (
    <Box component="span" sx={{ whiteSpace: "nowrap" }}>
      <IconButton
        title="Страница продука"
        color="primary"
        tabIndex={tabIndex}
        sx={{
          p: 0,
          fontSize: "15px",
          lineHeight: 1,
          display: "inline-flex",
          alignItems: "flex-end",
        }}
        size="small"
        target="_blank"
        href={routesMap.product.getRoute(product.id)}
      >
        <LinkOutlined sx={{ with: "12px", height: "12px" }} />
      </IconButton>
      <IconButton
        title="Bonjour"
        tabIndex={tabIndex}
        sx={{
          p: 0,
          width: "16px",
          height: "16px",
          ml: 2,
          color: red[700],
          fontSize: "15px",
          lineHeight: 1,
          display: "inline-flex",
          alignItems: "flex-end",
        }}
        size="small"
        target="_blank"
        href={`https://bonjour-dv.ru/product/${product.id}`}
      >
        <b>B</b>
      </IconButton>
      <IconButton
        title="Google"
        tabIndex={tabIndex}
        sx={{
          p: 0,
          width: "16px",
          height: "16px",
          ml: 2,
          color: blue[500],
        }}
        size="small"
        target="_blank"
        href={`https://www.google.com/search?q=${product.title} ${
          product.barcode?.value || ""
        }`}
      >
        <Google sx={{ with: "12px", height: "12px" }} />
      </IconButton>
    </Box>
  );
};
