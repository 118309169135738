import React, { useMemo, useState } from "react";
import {
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
} from "@mui/material";
import { useGetProductTypes } from "src/services/apiService";
import {
  Table,
  Link,
  ProductTypeCreateDialog,
  CheckEnLabel,
} from "src/components/common";
import { routesMap } from "src/routes";
import {
  Close,
  SearchOutlined,
  VolunteerActivismOutlined,
} from "@mui/icons-material";
import { Box } from "@mui/system";
import { ProductType } from "src/shared/api";

const Filter = ({ onConfirm }: { onConfirm: (value: string) => any }) => {
  const [name, setName] = useState("");

  const handleConfirm = (e: any) => {
    if (e.key === "Enter") {
      onConfirm(e.target.value);
    }
  };

  return (
    <TextField
      label="Название"
      fullWidth
      value={name}
      onChange={(e) => setName(e.target.value)}
      onKeyDown={handleConfirm}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {Boolean(name) && (
              <IconButton
                onClick={() => {
                  onConfirm("");
                  setName("");
                }}
              >
                <Close />
              </IconButton>
            )}
            <IconButton onClick={() => onConfirm(name)}>
              <SearchOutlined />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

interface Props {}

export const ProductTypesPage: React.FunctionComponent<Props> = ({}) => {
  const [name, setName] = useState("");
  const request = useGetProductTypes();

  const types =
    useMemo(
      () =>
        request.data
          ?.filter((item) =>
            name?.length
              ? item.name.toLocaleLowerCase().includes(name.toLocaleLowerCase())
              : true
          )
          ?.sort((a, b) => (a.name > b.name ? 1 : -1)),
      [name, request.data]
    ) || [];

  return (
    <Grid container spacing={4}>
      <Grid item xs={8}>
        <Filter onConfirm={setName} />
      </Grid>
      <Grid item xs={4}>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <ProductTypeCreateDialog onResponse={request.refetch} />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Paper sx={{ mt: 4 }}>
          <Table
            data={types}
            loading={request.isLoading}
            totalCount={request.data?.length || 0}
            limit={request.data?.length || 0}
            withPagination={false}
            headers={[
              {
                title: "ID",
                align: "right",
                extract: (item: ProductType) => item.id,
              },
              {
                title: "Название",
                extract: (item: ProductType) => (
                  <>
                    <Link blank href={routesMap.productType.getRoute(item.id)}>
                      {item.name}
                    </Link>{" "}
                    <CheckEnLabel>{item.name}</CheckEnLabel>
                  </>
                ),
              },
            ]}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};
