import { CheckOutlined, Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { apiService, useGetProductType } from "src/services/apiService";
import {
  ProductProperties,
  WithLoading,
  Barcodes,
  ProdcutImage,
  CharacteristcEdit,
  ProductLinks,
} from "src/components/common";
import { formattingService } from "src/services/formattingService";

//shared
import { Product as IProduct,  } from "src/shared/api";

const compareItems = (prev?: any, next?: any) =>
  Boolean(prev || next) ? prev === next : true;

const checkProductIsChanged = (product: IProduct | null = null, state: State) =>
  product
    ? [
        compareItems(product.description, state.description),
        compareItems(product.how_to_use, state.how_to_use),
        compareItems(product.ingredients, state.ingredients),
        compareItems(
          product.composite_name_extended,
          state.composite_name_extended
        ),
        compareItems(
          product.composite_name_properties,
          state.composite_name_properties
        ),
      ].some((item) => !item)
    : false;

interface State {
  description: string | null;
  how_to_use: string | null;
  ingredients: string | null;
  composite_name_extended: string | null;
  composite_name_properties: string | null;
}

interface Props {
  product?: IProduct;
  loading: boolean;
  onSave: () => any;
  template: (elements: { title: any; content: any; actions: any }) => any;
}

export const Product: React.FunctionComponent<Props> = ({
  product,
  onSave,
  loading,

  template,
}) => {
  const productTypeRequest = useGetProductType({
    variables: { id: product?.type?.id as number },
    skip: !product?.type,
  });

  const [state, changeState] = useState<State>({
    description: null,
    how_to_use: null,
    ingredients: null,
    composite_name_extended: null,
    composite_name_properties: null,
  });

  const setState = (data: any) => changeState({ ...state, ...data });

  useEffect(() => {
    if (product) {
      setState({
        description: product?.description,
        how_to_use: product?.how_to_use,
        ingredients: product?.ingredients,
        composite_name_extended: product?.composite_name_extended,
        composite_name_properties: product?.composite_name_properties,
      });
    }
  }, [product]);

  const handleChange = (e: any) =>
    setState({ [e.target.name]: e.target.value });

  const handleSave = () => {
    if (product) {
      handleChangeProduct(
        Object.keys(state)?.reduce(
          (prev, curr) =>
            //@ts-ignore
            compareItems(product[curr], state[curr])
              ? { ...prev }
              : //@ts-ignore
                { ...prev, [curr]: state[curr] },
          {}
        )
      );
    }
  };

  const handleChangeProduct = (value: Partial<IProduct>) => {
    if (product) {
      apiService
        .changeProduct({
          productId: product.id,
          value,
        })
        .then(handleRefetch);
    }
  };

  const handleRefetch = () => {
    if (onSave) {
      onSave();
    }
  };

  const isChanged = checkProductIsChanged(product, state);

  const title = (
    <WithLoading loading={loading} variant="text">
      {product?.title} {product && <ProductLinks product={product} />}
    </WithLoading>
  );
  const actions = (
    <>
      <Button
        color="secondary"
        variant="contained"
        disabled={!isChanged}
        onClick={handleSave}
      >
        Сохранить
      </Button>
    </>
  );

  const content = (
    <>
      <Grid container sx={{ display: "flex", mt: 0.25 }} spacing={3}>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <ProdcutImage
              product={product}
              onSave={handleRefetch}
              variant="list"
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Barcodes barcodes={product?.barcodes || []} loading={loading} />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox checked={Boolean(product?.moderated)} />}
            label="Проверен"
            onChange={() =>
              handleChangeProduct({ moderated: !product?.moderated })
            }
          />
        </Grid>
        <Grid item xs={12}>
          <WithLoading loading={loading} height={25}>
            {product && (
              <ProductProperties withSeries product={product} onSave={onSave} />
            )}
          </WithLoading>
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", flexWrap: "wrap" }}>
          {product &&
            productTypeRequest?.data?.attributes
              ?.sort((a, b) => (a.name > b.name ? 1 : -1))
              ?.map((attribute, attributeIndex) => (
                <Box ml={1} key={attribute.id}>
                  <CharacteristcEdit
                    onSave={handleSave}
                    onAddValue={productTypeRequest.refetch}
                    product={product}
                    attribute={attribute}
                    characteristc={product?.characteristics?.find(
                      (characteristic) =>
                        characteristic?.attribute?.id === attribute.id
                    )}
                  />
                </Box>
              ))}
        </Grid>
        <Grid item xs={12} md={8}>
          <WithLoading loading={loading} height={40}>
            {product && (
              <TextField
                size="small"
                label="Дополнительное название"
                fullWidth
                multiline
                value={state.composite_name_extended || ""}
                onChange={handleChange}
                name="composite_name_extended"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {compareItems(
                        product.composite_name_extended,
                        state.composite_name_extended
                      ) && (
                        <Icon title="Сохнанено" color="primary">
                          <CheckOutlined />
                        </Icon>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </WithLoading>
        </Grid>
        <Grid item xs={12} md={4}>
          <WithLoading loading={loading} height={40}>
            {product && (
              <TextField
                disabled
                size="small"
                label="Характеристики"
                fullWidth
                multiline
                value={state.composite_name_properties || ""}
                onChange={handleChange}
                name="composite_name_properties"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {Boolean(state.composite_name_properties) && (
                        <IconButton
                          title="Удалить"
                          color="error"
                          onClick={() =>
                            setState({ composite_name_properties: "" })
                          }
                        >
                          <Close />
                        </IconButton>
                      )}
                      {compareItems(
                        product.composite_name_properties,
                        state.composite_name_properties
                      ) && (
                        <Icon title="Сохнанено" color="primary">
                          <CheckOutlined />
                        </Icon>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </WithLoading>
        </Grid>
        <Grid item xs={12}>
          <WithLoading loading={loading} height={40}>
            {product && (
              <TextField
                size="small"
                label="Описание"
                fullWidth
                multiline
                value={state.description || ""}
                onChange={handleChange}
                name="description"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {compareItems(product.description, state.description) && (
                        <Icon title="Сохнанено" color="primary">
                          <CheckOutlined />
                        </Icon>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </WithLoading>
        </Grid>
        <Grid item xs={12}>
          <WithLoading loading={loading} height={40}>
            {product && (
              <TextField
                size="small"
                label="Способ применения"
                fullWidth
                multiline
                value={state.how_to_use || ""}
                onChange={handleChange}
                name="how_to_use"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {compareItems(product.how_to_use, state.how_to_use) && (
                        <Icon title="Сохнанено" color="primary">
                          <CheckOutlined />
                        </Icon>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </WithLoading>
        </Grid>
        <Grid item xs={12}>
          <WithLoading loading={loading} height={40}>
            {product && (
              <TextField
                size="small"
                label="Состав"
                fullWidth
                multiline
                value={state.ingredients || ""}
                onChange={handleChange}
                name="ingredients"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {compareItems(product.ingredients, state.ingredients) && (
                        <Icon title="Сохнанено" color="primary">
                          <CheckOutlined />
                        </Icon>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </WithLoading>
        </Grid>
      </Grid>
      <WithLoading loading={loading} height={48}>
        <List
          sx={{ mt: 4 }}
          subheader={
            <ListSubheader component="div">Названия поставщиков</ListSubheader>
          }
        >
          {product?.names
            ?.sort((a, b) => b.created_time - a.created_time)
            ?.map((item, index) => (
              <ListItem
                key={item.id}
                dense
                divider={product?.names && index + 1 < product?.names?.length}
              >
                <ListItemText
                  primary={item.value}
                  secondary={`${item.supplier.name}, ${formattingService.date(
                    item.created_time * 1000
                  )}`}
                />
              </ListItem>
            ))}
        </List>
      </WithLoading>
    </>
  );

  return template({ title, content, actions });
};
