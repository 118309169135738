import { LinkOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { routesMap } from "src/routes";
import { useGetProductTypes } from "src/services/apiService";

//shared
import { ProductType } from "src/shared/api";

interface Props {
  disabled?: boolean;
  nullable?: boolean;
  label?: string;
  productTypeId?: number | null;
  onSelect?: (value: ProductType | null) => any;
  onSave?: (value: ProductType | null) => any;
}

export const ProductTypeSelect: React.FunctionComponent<Props> = ({
  productTypeId,
  disabled,
  label = "Тип продукта",
  onSelect,
  onSave,
  nullable = false,
}) => {
  const [value, setValue] = useState<null | ProductType>(null);

  const request = useGetProductTypes({ skip: disabled });

  const types = useMemo(
    () => request?.data?.sort((a, b) => (a.name > b.name ? 1 : -1)),
    [request.data]
  );

  useEffect(() => {
    if (disabled) setValue(null);
  }, [disabled]);

  useEffect(() => {
    if (productTypeId && types) {
      const valueFromList = types.find((item) => item.id === productTypeId);
      if (valueFromList) {
        handleChange(null, valueFromList);
      }
    }
  }, [productTypeId, types]);

  const handleChange = (e: any, value: null | ProductType) => {
    setValue(value);
    if (onSelect) onSelect(value);
  };

  const handleSave = () => {
    if (onSave) onSave(value);
  };

  return (
    <Autocomplete
      value={value}
      disabled={disabled}
      onChange={handleChange}
      getOptionLabel={(item) => item.name}
      options={types || []}
      loading={request.isLoading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                <InputAdornment position="end">
                  {value && (
                    <IconButton
                      title={value && `Открыть тип ${value.name}`}
                      href={routesMap.productType.getRoute(value.id)}
                      target="_blank"
                    >
                      <LinkOutlined />
                    </IconButton>
                  )}
                  {onSave && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleSave}
                      disabled={!value || disabled}
                    >
                      Сохранить
                    </Button>
                  )}
                </InputAdornment>
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
