import { LinkOutlined } from "@mui/icons-material";
import { Autocomplete, IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { routesMap } from "src/routes";
import { useGetManufacturers } from "src/services/apiService";

//shared
import { Manufacturer } from "src/shared/api";

interface Props {
  disabled?: boolean;
  manufacturerId?: number | null;
  label?: string;
  onSelect: (value: Manufacturer | null) => any;
}

export const ManufacturerSelect: React.FunctionComponent<Props> = ({
  disabled,
  manufacturerId,
  label = "Производитель",
  onSelect,
}) => {
  const request = useGetManufacturers();

  const [value, setValue] = useState<null | Manufacturer>(null);

  const types = useMemo(
    () => request.data?.sort((a, b) => (a.name > b.name ? 1 : -1)),
    [request.data]
  );

  useEffect(() => {
    if (manufacturerId && types) {
      const valueFromList = types.find((item) => item.id === manufacturerId);
      if (valueFromList) {
        setValue(valueFromList);
      }
    }
  }, [manufacturerId, types]);

  const handleChange = (e: any, value: null | Manufacturer) => {
    setValue(value);
    onSelect(value);
  };

  return (
    <Autocomplete
      value={value}
      disabled={disabled}
      onChange={handleChange}
      getOptionLabel={(item) => item.name ||  'Производитель без названия'}
      options={types || []}
      loading={request.isLoading}
      renderInput={(params) => <TextField {...params} label={label} 
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <>
            <InputAdornment position="end">
              {value && (
                <IconButton
                  href={routesMap.manufacturer.getRoute(value.id)}
                  target="_blank"
                >
                  <LinkOutlined />
                </IconButton>
              )}
            </InputAdornment>
            {params.InputProps.endAdornment}
          </>
        ),
      }}
      />}
    />
  );
};
