import React from "react";
import { CopyToClipboard, WithLoading } from "src/components/common";

//shared
import { Barcode } from "src/shared/api";

interface Props {
  barcodes: Barcode[];
  tabIndex?: number;
  loading?: boolean;
}

export const Barcodes: React.FunctionComponent<Props> = ({
  barcodes,
  tabIndex = 0,
  loading = false,
}) => {
  return (
    <WithLoading loading={loading} variant="text" width={100}>
      <>
        {barcodes.map((barcode) => (
          <CopyToClipboard
            key={barcode.id}
            text={barcode?.value || ""}
            tabIndex={tabIndex}
          >
            [{barcode.value}]
          </CopyToClipboard>
        ))}
      </>
    </WithLoading>
  );
};
