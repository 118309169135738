import { EditOutlined } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { apiService } from "src/services/apiService";

//shared
import { ProductTypeAttribute } from "src/shared/api";

interface Props {
  attribute: ProductTypeAttribute;
  disabled?: boolean;
  onResponse: () => any;
}

export const ChangeAttributeDialog: React.FunctionComponent<Props> = ({
  attribute,
  onResponse,
  disabled = false,
}) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");

  useEffect(() => {
    if (attribute.name) {
      setName(attribute.name);
    }
  }, [attribute]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    if (name && attribute) {
      apiService
        .changeAttribute({
          attributeId: attribute.id,
          attribute: { name },
        })
        .then(() => {
          onResponse();
          handleClose();
        });
    }
  };

  return (
    <>
      <IconButton
        size="small"
        onClick={handleOpen}
        title="Изменить значение"
        disabled={disabled}
      >
        <EditOutlined />
      </IconButton>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Изменение атрибута "{attribute.name}"</DialogTitle>
        <DialogContent>
          <TextField
            sx={{ mt: 1 }}
            fullWidth
            value={name}
            label="Название"
            onChange={(e) => setName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Закрыть</Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={!name}
            onClick={handleConfirm}
          >
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
