import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
} from "@mui/material";
import React, { useState } from "react";
import { ProductTypeSelect } from "src/components/common";
import { routesMap } from "src/routes";
import { FCWithChildren } from "src/types/app";

import { ProductType } from "src/shared/api";

interface Props {
  onSave: (value: ProductType | null) => any;
  title?: React.ReactNode;
  productTypeId?: number | null;
}

export const ProductTypeSelectDialog: FCWithChildren<Props> = ({
  onSave,
  children,
  productTypeId,
  title = "Выбор типа продукта",
}) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<null | ProductType>(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setValue(null);
  };

  const handleSave = () => {
    onSave(value);

    setOpen(false);
  };

  return (
    <>
      {children && //@ts-ignore
        React.cloneElement(children, { onClick: handleOpen })}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>
          <Icon>
            <routesMap.productTypes.icon />
          </Icon>{" "}
          {title}
        </DialogTitle>
        <DialogContent>
          <Box mt={2}>
            <ProductTypeSelect
              onSelect={setValue}
              productTypeId={productTypeId}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Отмена</Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={Boolean(productTypeId === value?.id)}
            onClick={handleSave}
          >
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
