import { format } from "date-fns";
import locale from "date-fns/locale/ru";

class FormattingService {
  constructor() {}

  cost(number: number) {
    return String(number).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1 ");
  }

  /**
   * declOfNum(['Яблоко','Яблока','Яблок'])(2); // Яблока
   */
  declOfNum(titles: Array<string>) {
    const cases = [2, 0, 1, 1, 1, 2];
    return (passedNumber: number) => {
      const number = Math.abs(passedNumber);
      return titles[
        number % 100 > 4 && number % 100 < 20
          ? 2
          : cases[number % 10 < 5 ? number % 10 : 5]
      ];
    };
  }
  date(date: string | number, template: string = "yyyy.MM.dd HH:mm:ss") {
    return format(new Date(date), template, {
      locale,
    });
  }

  phone(number?: string | null) {
    const cleaned = ("" + number).replace(/\D/g, "");

    const match = cleaned.match(/^(7|)?(\d{3})(\d{3})(\d{2})(\d{2})$/);

    if (match) {
      return [
        "+",
        match[1],
        " (",
        match[2],
        ") ",
        match[3],
        "-",
        match[4],
        "-",
        match[5],
      ].join("");
    }

    return null;
  }
  checkForLatin(string: string | null) {
    return string ? /[a-zA-Z]+/gu.test(string) : false;
  }
}

export const formattingService = new FormattingService();
