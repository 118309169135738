import { useState } from "react";

export const useSelectItems = <T>(
  { extractId } = { extractId: (item: any) => item.id }
) => {
  const [items, changeitems] = useState(new Map<number | string, T>());

  const setItems = (items: T[]) =>
    handleSetItems(new Map(items.map((item) => [extractId(item), item])));

  const setItem = (item: T) => handleSetItems(toggleItem(item));

  const handleSetItems = (itemsMap: Map<number | string, T>) =>
    changeitems(new Map(itemsMap.entries()));

  const toggleItem = (item: T) => {
    const id = extractId(item);
    const itemsCopy = items;
    if (id) {
      itemsCopy.has(id) ? itemsCopy.delete(id) : itemsCopy.set(id, item);
    }

    return itemsCopy;
  };

  const valuesList = Array.from(items.values());

  return { items, setItem, setItems, valuesList };
};
