enum Roles {
  "ROLE_SUPREME_LORD",
}

export interface User {
  id: number;
  email: string | null;
  first_name: string | null;
  father_name: string | null;
  last_name: string | null;
  birthday: string | null;
  gender: boolean;
  discount_card: string | null;
  military: boolean;
  pensioner: boolean;
  is_subscribed_email: boolean;
  is_subscribed_sms: boolean;
  roles: Roles[];
  phone: string;
  full_name: string | null;
  private_comment: string | null;
  is_email_confirmed: boolean;
}

export enum ProductTypeAttributeType {
  "numeric" = "numeric",
  "string" = "string",
  "bool" = "bool",
}

export interface ProductTypeAttributeStringValue {
  id: number;
  value: string;
}

export interface AbstarctProductTypeAttribute {
  id: number;
  name: string;
  types?: ProductType[];
}

export interface ProductTypeAttributeString
  extends AbstarctProductTypeAttribute {
  type: ProductTypeAttributeType.string;
  list_possible_values?: ProductTypeAttributeStringValue[];
}

export interface ProductTypeAttributeNumeric
  extends AbstarctProductTypeAttribute {
  type: ProductTypeAttributeType.numeric;
  unit: string;
}

export interface ProductTypeAttributeBool extends AbstarctProductTypeAttribute {
  type: ProductTypeAttributeType.bool;
}
export type ProductTypeAttribute =
  | ProductTypeAttributeString
  | ProductTypeAttributeNumeric
  | ProductTypeAttributeBool;

export interface ProductType {
  id: number;
  name: string;
  attributes?: ProductTypeAttribute[];
}

export interface Alias {
  id: number;
  value: string;
}

export interface MetaTagData {
  title: string | null;
  description: string | null;
  keywords: string | null;
}
export interface Brand {
  id: number;
  name: string;
  slug: string | null;
  adapted_name: string | null;
  title: string | null;
  description: string | null;
  aliases?: Alias[];
  manufacturer?: Manufacturer;
  logoBase64?: string | null;
  meta_tag_data?: MetaTagData;
  highlighted?: boolean;
}

export interface Supplier {
  name: string;
  alias: string;
}
export interface UndefinedManufacturer {
  id: number;
  value: string;
  supplier: Supplier;
  createTime: number;
}

export interface UndefinedBrand {
  id: number;
  value: string;
  supplier: Supplier;
  createTime: number;
}

export interface Manufacturer {
  id: number;
  name: string;
  slug: string | null;
  aliases?: Alias[];
  brands?: Brand[];
}

export interface Image {
  id?: number;
  uri: string;
  published?: boolean;
}

export interface Characteristic {
  id: number;
  attribute: ProductTypeAttribute;
  adapt_value: string;
  value: number | boolean | ProductTypeAttributeString;
  type: ProductTypeAttributeType;
}
export interface Barcode {
  id: number;
  value: string;
}

export interface Series {
  id: number;
  name: string;
}

export interface ProductName {
  id: number;
  value: string;
  supplier: Supplier;
  created_time: number;
}

export interface Product {
  id: number;
  title: string;
  manufacturer: Manufacturer | null;
  brand: Brand | null;
  type: ProductType;
  default_image: Image | null;
  color_image: Image | null;
  characteristics: Characteristic[];
  barcode: Barcode | null;
  barcodes: Barcode[];
  description?: string | null;
  ingredients?: string | null;
  how_to_use?: string | null;
  composite_name_extended?: string | null;
  composite_name_properties?: string | null;
  images?: Image[];
  series?: Series | null;
  names?: ProductName[];
  moderated?: boolean;
}




export interface Review {
  id: number;
  updated_at: string;
  created_at: string;
  rating: number;
  comment: string;
  product: Product;
  enabled: boolean;
  user: User;
}
