import { Add, Close, SaveOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemIcon,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import Color from "color";
import React, { useEffect, useState } from "react";
import { apiService } from "src/services/apiService";
import {
  AddValueToAttributeDialog,
  CharacteristcEditValueHelper,
} from "src/components/common";

//shared
import {
  Characteristic,
  Product,
  ProductTypeAttribute,
  ProductTypeAttributeType,
} from "src/shared/api";

export const characteristcEditInputWidth = {
  [ProductTypeAttributeType.string]: "140px",
  [ProductTypeAttributeType.numeric]: "100px",
  [ProductTypeAttributeType.bool]: "100px",
};

interface Props {
  attribute: ProductTypeAttribute;
  characteristc?: Characteristic | null;
  product: Product;
  onSave: () => any;
  onAddValue?: () => any;
  tabIndex?: number;
}

export const CharacteristcEdit: React.FunctionComponent<Props> = ({
  attribute,
  characteristc,
  product,
  onSave,
  onAddValue,
  tabIndex = 0,
}) => {
  const [numericValue, setNumericValue] = useState<number | null>(null);
  const [stringValue, setStringValue] = useState<string>("");
  const [boolValue, setBoolValue] = useState<boolean | null>(null);

  useEffect(() => {
    if (characteristc?.type === ProductTypeAttributeType.numeric) {
      setNumericValue(characteristc.value as number);
    }
    if (characteristc?.type === ProductTypeAttributeType.string) {
      //@ts-ignore
      setStringValue(characteristc.value.value as number);
    }
    if (characteristc?.type === ProductTypeAttributeType.bool) {
      //@ts-ignore
      setBoolValue(characteristc.value as boolean);
    }
  }, [characteristc]);

  const handleChangeBoolValue = () => {
    const value = { null: true, true: false, false: null }[String(boolValue)];
    setBoolValue(value!);
    if (value !== null) {
      handleChange({ value });
    }
  };

  const handleChangeStringValue = (value: string) => {
    setStringValue(value);
    if (value !== null) {
      handleChange({ value: { value } });
    }
  };

  const handleSaveNumericValue = (value?: any) => {
    console.log(value);
    if (typeof value === "number") {
      handleChange({ value });
    } else if (numericValue !== null && numericValue !== characteristc?.value) {
      handleChange({ value: numericValue });
    }
  };

  const handleChange = (value: any) =>
    apiService
      .changeProductCharacteristic({
        productId: product.id,
        attributeId: attribute.id,
        value,
      })
      .then(onSave);

  const redBg = Color(red[600]).alpha(0.1).toString();
  const greenBg = Color(green[600]).alpha(0.1).toString();

  if (attribute.type === ProductTypeAttributeType.string)
    return (
      <Box sx={{ height: "52px" }}>
        <FormControl
          size="small"
          //@ts-ignore
        >
          <InputLabel
            tabIndex={-1}
            id={`attribute-${attribute.id}-label`}
            shrink
            margin="dense"
          >
            {attribute.name}
          </InputLabel>
          <Select
            inputProps={{
              tabIndex,
            }}
            labelId={`attribute-${attribute.id}-label`}
            id={`attribute-${attribute.id}`}
            label={attribute.name}
            variant="outlined"
            sx={{
              paddingRight: "0 !important",
              width: characteristcEditInputWidth[attribute.type],

              backgroundColor:
                //@ts-ignore
                !characteristc?.value?.value ? redBg : greenBg,
            }}
            //@ts-ignore
            value={stringValue}
            onChange={(e) => handleChangeStringValue(e.target.value)}
            IconComponent={() => null}
            endAdornment={
              <InputAdornment position="end">
                <AddValueToAttributeDialog
                  attribute={attribute}
                  onResponse={onAddValue}
                >
                  <IconButton
                    size="small"
                    title="Добавить новое значение"
                    tabIndex={-1}
                  >
                    <Add />
                  </IconButton>
                </AddValueToAttributeDialog>
              </InputAdornment>
            }
          >
            {attribute.list_possible_values
              ?.sort((a, b) => (a.value > b.value ? 1 : -1))
              ?.map((value) => (
                <MenuItem key={value.value} value={value.value} dense>
                  {value.value}
                </MenuItem>
              ))}
            <Divider />
            <MenuItem value={""} dense color="error">
              <ListItemIcon>
                <Close fontSize="small" />
              </ListItemIcon>
              нет значения
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
    );

  if (attribute.type === ProductTypeAttributeType.numeric)
    return (
      <Box
        sx={{
          height: "52px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TextField
          tabIndex={tabIndex}
          onBlur={handleSaveNumericValue}
          variant="outlined"
          InputLabelProps={{
            margin: "dense",
            shrink: true,
          }}
          size="small"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSaveNumericValue();
            }
          }}
          InputProps={{
            sx: {
              width: characteristcEditInputWidth[attribute.type],

              p: 0,
              backgroundColor:
                //@ts-ignore
                !characteristc?.value ? redBg : greenBg,
            },
            notched: true,
            endAdornment: (
              <InputAdornment position="end">
                {Boolean(
                  numericValue && characteristc?.value !== numericValue
                ) && (
                  <IconButton
                    onClick={handleSaveNumericValue}
                    size="small"
                    tabIndex={-1}
                  >
                    <SaveOutlined />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
          label={`${attribute.name} ${attribute.unit}`}
          value={numericValue || ""}
          onChange={(e) => setNumericValue(parseInt(e.target.value, 10))}
        />
        {typeof numericValue === "number" &&
          characteristc?.value !== numericValue && (
            <Box sx={{ display: "flex", flexWrap: "wrap", mt: 0.5 }}>
              <CharacteristcEditValueHelper
                unit={attribute.unit}
                value={numericValue}
                onClick={handleSaveNumericValue}
              />
            </Box>
          )}
        {characteristc?.value === numericValue && (
          <Typography variant="caption" component="div" align="center">
            {characteristc?.adapt_value}
          </Typography>
        )}
      </Box>
    );
  if (attribute.type === ProductTypeAttributeType.bool)
    return (
      <Box sx={{ height: "52px" }}>
        <Button
          sx={{
            width: characteristcEditInputWidth[attribute.type],
            fontSize: "10px",
          }}
          tabIndex={tabIndex}
          onClick={handleChangeBoolValue}
          size="small"
          color={
            //@ts-ignore
            { true: "primary", false: "error", null: "primary" }[boolValue]
          }
          variant={
            typeof characteristc?.value === "boolean" ? "contained" : "outlined"
          }
        >
          {attribute?.name}
        </Button>
      </Box>
    );
  return <div></div>;
};
