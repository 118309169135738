import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
} from "@mui/material";
import React, { FC, PropsWithChildren, useState } from "react";
import { ProductSeriesSelect } from "src/components/common";
import { routesMap } from "src/routes";

//shared
import { Brand, Series } from "src/shared/api";

interface Props {
  onSave: (value: Series) => any;
  title?: React.ReactNode;
  seriesId?: number | null;
  brand: Brand;
}

export const ProdcutSeriesSelectDialog: FC<PropsWithChildren<Props>> = ({
  onSave,
  children,
  seriesId,
  brand,
  title = "Выбор серии",
}) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<null | Series>(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setValue(null);
  };

  const handleSave = () => {
    if (value) {
      onSave(value);
    }
    setOpen(false);
  };

  return (
    <>
      {children && //@ts-ignore
        React.cloneElement(children, { onClick: handleOpen })}
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        keepMounted={false}
      >
        <DialogTitle>
          <Icon>
            <routesMap.series.icon />
          </Icon>{" "}
          {title}
        </DialogTitle>
        <DialogContent>
          <Box mt={2}>
            <ProductSeriesSelect
              onSelect={setValue}
              brand={brand}
              seriesId={value?.id}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Отмена</Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={!value}
            onClick={handleSave}
          >
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
