import useSWR from "swr";
import axios from "axios";
import { enqueueSnackbar } from "notistack";

const fetcher = axios.create({
  withCredentials: true,
});

const addNotification = (data: any) =>
  enqueueSnackbar(JSON.stringify(data), {
    variant: "error",
    autoHideDuration: null,
    hideIconVariant: true,
  });

const handleResponse = (res: any) => {
  if (res.data?.errors?.length) {
    res.data?.errors?.forEach(addNotification);
  }
  return res.data;
};

const handleError = (e: any) => {
  addNotification(e.data || e.message);
  return e;
};

type ResponseDataWithErrors<T> = {} & { errors?: any };

export const request = {
  get: <T>(input: string) =>
    fetcher
      .get<ResponseDataWithErrors<T> & { errors?: any }>(input)
      .then(handleResponse)
      .catch(handleError),

  post: <T>(input: string, init?: any, config?: any) =>
    fetcher
      .post<ResponseDataWithErrors<T> & { errors?: any }>(input, init, config)
      .then(handleResponse)
      .catch(handleError),
  patch: <T>(input: string, init?: any) =>
    fetcher
      .patch<ResponseDataWithErrors<T> & { errors?: any }>(input, init)
      .then(handleResponse)
      .catch(handleError),
  put: <T>(input: string, init?: any) =>
    fetcher
      .put<ResponseDataWithErrors<T> & { errors?: any }>(input, init)
      .then(handleResponse)
      .catch(handleError),
  delete: <T>(input: string) =>
    fetcher
      .delete<ResponseDataWithErrors<T> & { errors?: any }>(input)
      .then(handleResponse)
      .catch(handleError),
};

type RequestQueryParamsType = Record<string | number, any>;

const stringifyQueryParams = (rawQuery?: RequestQueryParamsType): string => {
  const query = rawQuery || {};

  const keys = Object.keys(query).filter(
    (key) =>
      typeof query[key] !== "undefined" &&
      !["", null, "null"].some((value) => query[key] === value)
  );

  return keys.length
    ? `?${keys
        .map((key) =>
          typeof query[key] === "object" && !Array.isArray(query[key])
            ? stringifyQueryParams(query[key]).substring(1)
            : encodeURIComponent(key) +
              "=" +
              encodeURIComponent(
                Array.isArray(query[key]) ? query[key].join(",") : query[key]
              )
        )
        .join("&")}`
    : "";
};

export const addQueryParams = ({
  href,
  query = {},
}: {
  href: string;
  query?: RequestQueryParamsType;
}): string => `${href}${stringifyQueryParams(query)}`;

export type ResponseWithMeta<T> = { data: T; total_count: number };

export type RequestHookParams<T> = { variables?: T; skip?: boolean };

export type ListRequestVariables<T, V = {}> = {
  query?: {
    limit?: number | string | null;
    offset?: number | string | null;
  } & T;
} & V;

export const useRequest = <T>(href: string, params?: RequestHookParams<any>) =>
  useSWR<T>(
    params?.skip ? null : addQueryParams({ href, query: params?.variables }),
    (url) => request.get<T>(url),
    {
      revalidateOnFocus: false,
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Never retry on statuses.
        if ([403, 404].includes(error.status)) return;

        // Only retry up to 3 times.
        if (retryCount >= 3) return;
      },
    }
  );
