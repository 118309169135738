import {
  useParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useMemo } from "react";

export function useRouter() {
  const [query, setSearchParams] = useSearchParams();
  const location = useLocation();
  const push = useNavigate();
  const params = useParams();

  return useMemo(() => {
    return {
      push,
      pathname: location.pathname,
      location,
      query,
      params,
    };
  }, [query, location, params]);
}
