import { Add } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { apiService } from "src/services/apiService";
import { CheckEnLabel } from "src/components/common";

interface Props {
  onResponse: () => any;
}

export const ProductTypeCreateDialog: React.FunctionComponent<Props> = ({
  onResponse,
}) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");

  const handleOpen = () => {
    setOpen(true);
    setName("");
  };

  const handleClose = () => {
    setOpen(false);
    setName("");
  };

  const handleChangeName = (e: any) => setName(e.currentTarget.value);

  const handleConfirm = () =>
    apiService.createProductType({ name: name.trim() }).then(() => {
      onResponse();
      handleClose();
    });

  return (
    <>
      <Fab
        size="small"
        onClick={handleOpen}
        color="secondary"
        title="Создать тип"
      >
        <Add />
      </Fab>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Создание типа продукта</DialogTitle>
        <DialogContent>
          <TextField
            sx={{ mt: 1 }}
            fullWidth
            value={name}
            onChange={handleChangeName}
            label="Название"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CheckEnLabel>{name}</CheckEnLabel>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Закрыть</Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={!name}
            onClick={handleConfirm}
          >
            Создать
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
