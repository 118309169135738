import { Chip } from "@mui/material";
import React from "react";
import {
  BrandSelectDialog,
  ProductTypeSelectDialog,
  ManufacturerSelectDialog,
  ProdcutSeriesSelectDialog,
  BrandLabel,
  SeriesLabel,
  ProductTypeLabel,
} from "src/components/common";
import { routesMap } from "src/routes";
import { LinkOutlined } from "@mui/icons-material";
import { apiService } from "src/services/apiService";

//shared
import { Brand, Manufacturer, Product, ProductType, Series } from "src/shared/api";

export enum ProductProperyLabelVariant {
  "productType" = "productType",
  "brand" = "brand",
  "default" = "default",
  "manufacturer" = "manufacturer",
  "series" = "series",
}

const colors = {
  [ProductProperyLabelVariant.productType]: "success",
  [ProductProperyLabelVariant.series]: "info",
  [ProductProperyLabelVariant.default]: "default",
  [ProductProperyLabelVariant.brand]: "default",
  [ProductProperyLabelVariant.manufacturer]: "default",
};

const variants = {
  [ProductProperyLabelVariant.productType]: "outlined",
  [ProductProperyLabelVariant.default]: "filled",
  [ProductProperyLabelVariant.brand]: "filled",
  [ProductProperyLabelVariant.manufacturer]: "filled",
  [ProductProperyLabelVariant.series]: "filled",
};

const icons = {
  [ProductProperyLabelVariant.brand]: <routesMap.brands.icon />,
  [ProductProperyLabelVariant.productType]: <routesMap.productTypes.icon />,
  [ProductProperyLabelVariant.manufacturer]: <routesMap.manufacturers.icon />,
  [ProductProperyLabelVariant.series]: <routesMap.series.icon />,
  [ProductProperyLabelVariant.default]: null,
};

interface Props {
  brand?: Brand | null;
  series?: Series | null;
  tabIndex?: number;
  onSave?: () => any;
  variant?: ProductProperyLabelVariant;
  productType?: ProductType | null;
  product?: Product | null;
  manufacturer?: Manufacturer | null;
}

export const ProductProperyLabel: React.FunctionComponent<Props> = ({
  brand,
  productType,
  product,
  manufacturer,
  series,
  onSave,
  tabIndex = 0,
  variant = ProductProperyLabelVariant.default,
}) => {
  const handleSelectBrand = (brand: Brand) => {
    if (product) {
      return apiService
        .changeProduct({
          productId: product.id,
          value: { brand: { id: brand.id } },
        })
        .then(onSave);
    }
  };
  const handleSelectManufacturer = (manufacturer: Manufacturer) => {
    if (product) {
      return apiService
        .changeProduct({
          productId: product.id,
          value: {
            manufacturer: {
              id: manufacturer.id,
            },
          },
        })
        .then(onSave);
    }
  };

  const handleSelectProductType = (type: ProductType | null) => {
    if (product) {
      return apiService
        .changeProduct({
          productId: product.id,
          value: { type: type ? { id: type.id } : null },
        })
        .then(onSave);
    }
  };
  const handleSelectSeries = (series: Series) => {
    if (product) {
      return apiService
        .changeProduct({
          productId: product.id,
          value: { series: { id: series.id } },
        })
        .then(onSave);
    }
  };

  if (variant === ProductProperyLabelVariant.manufacturer) {
    return (
      <ManufacturerSelectDialog
        manufacturerId={manufacturer?.id}
        onSave={handleSelectManufacturer}
        title={
          product && (
            <span>
              Производитель для продукта
              <br />
              {product.title}
            </span>
          )
        }
      >
        <Chip
          title="Производитель"
          sx={{ opacity: manufacturer ? 1 : 0.8 }}
          color={colors[variant] as "success" | "default"}
          variant={manufacturer ? "filled" : "outlined"}
          icon={icons[variant]}
          size="small"
          clickable={Boolean(product)}
          tabIndex={tabIndex}
          label={
            manufacturer
              ? manufacturer?.name || "Производитель без названия"
              : null
          }
          onDelete={
            manufacturer
              ? () =>
                  // @ts-ignore
                  window
                    ?.open(
                      routesMap.manufacturer.getRoute(manufacturer.id),
                      "_blank"
                    )
                    ?.focus()
              : undefined
          }
          deleteIcon={manufacturer ? <LinkOutlined /> : undefined}
        />
      </ManufacturerSelectDialog>
    );
  }

  if (variant === ProductProperyLabelVariant.brand) {
    return (
      <BrandSelectDialog
        brandId={brand?.id}
        onSave={handleSelectBrand}
        title={
          product && (
            <span>
              Бренд для продукта
              <br />
              {product.title}
            </span>
          )
        }
      >
        <BrandLabel
          tabIndex={tabIndex}
          linkIcon
          brand={brand}
          onDelete={
            brand
              ? () =>
                  // @ts-ignore
                  window
                    ?.open(routesMap.brand.getRoute(brand.id), "_blank")
                    ?.focus()
              : undefined
          }
        />
      </BrandSelectDialog>
    );
  }

  if (variant === ProductProperyLabelVariant.productType) {
    return (
      <ProductTypeSelectDialog
        productTypeId={productType?.id}
        onSave={handleSelectProductType}
        title={
          product && (
            <span>
              Тип продукта для продукта
              <br />
              {product.title}
            </span>
          )
        }
      >
        <ProductTypeLabel
          type={productType}
          onDelete={
            productType
              ? () =>
                  // @ts-ignore
                  window
                    ?.open(
                      routesMap.productType.getRoute(productType.id),
                      "_blank"
                    )
                    ?.focus()
              : undefined
          }
          tabIndex={tabIndex}
          linkIcon
        />
      </ProductTypeSelectDialog>
    );
  }
  if (variant === ProductProperyLabelVariant.series && brand) {
    return (
      <ProdcutSeriesSelectDialog
        brand={brand}
        seriesId={product?.series?.id}
        onSave={handleSelectSeries}
        title={
          product && (
            <span>
              Серия для продукта
              <br />
              {product.title}
            </span>
          )
        }
      >
        <SeriesLabel
          tabIndex={tabIndex}
          linkIcon
          series={series}
          onDelete={
            series
              ? () =>
                  // @ts-ignore
                  window
                    ?.open(routesMap.seriesSingle.getRoute(series.id), "_blank")
                    ?.focus()
              : undefined
          }
        />
      </ProdcutSeriesSelectDialog>
    );
  }
  return null;
};
