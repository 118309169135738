import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import {
  ProductTypeSelect,
  WithLoading,
  ProductPreviewWithEdit,
} from "src/components/common";
import { apiService, useGetAussInStock, useGetBonjourInStock } from "src/services/apiService";
import { formattingService } from "src/services/formattingService";

//shared
import { Product, ProductType } from "src/shared/api";
import { useSelectItems } from "src/shared/hooks";

enum InStockFilter {
  "bonjour" = "bonjour-dv.ru",
  "instock" = "instock-dv.ru",
}

interface Props {
  filters?: ReactNode;
  onSave: () => any;
  products?: Product[] | null;
  loading: boolean;
  offset: number;
  limit: number;
  productType?: ProductType | null;
  count?: number;
  onChangePage?: (page: number) => any;
  withStock?: boolean;
}

export const Products: React.FunctionComponent<Props> = ({
  onSave,
  products,
  loading,
  offset,
  limit,
  productType,
  onChangePage,
  count = 0,
  filters,
  withStock = false,
}) => {
  const [tableId, setTableId] = useState("table_");

  useEffect(() => {
    setTableId(`table_${(Math.random() + 1).toString(36).substring(7)}`);
  }, []);

  const [inStockFilter, setInStockFilter] = useState<string | InStockFilter>(
    ""
  );
  const selectItems = useSelectItems<Product>();

  const ids = products?.map((product) => product?.id) || [];

  const requestBonjourInStockProducts = useGetBonjourInStock({
    variables: { ids: ids?.join(",") || "" },
    skip: !ids?.length || inStockFilter !== InStockFilter.bonjour,
  });

  const requestAussInStockProducts = useGetAussInStock({
    variables: { ids },
    skip: !ids?.length || inStockFilter !== InStockFilter.instock,
  });

  const inStockProducts = requestBonjourInStockProducts?.data || requestAussInStockProducts?.data || [];

  const handleChangeProductType = (type: ProductType | null) => {
    if (type && selectItems.valuesList) {
      apiService
        .addProductsToType({
          typeId: type.id,
          products: selectItems.valuesList.map((product) => product.id),
        })
        .then(() => {
          onSave();
          selectItems.setItems([]);
        });
    }
  };

  const prouctsWithoutCharacteristics =
    useMemo(
      () => products?.filter((item) => !item.characteristics?.length),
      [products]
    ) || [];

  const pagination = onChangePage && (
    <TablePagination
      component="div"
      page={offset / limit}
      count={count}
      rowsPerPage={limit}
      onPageChange={(e, page) => {
        onChangePage(page);
        //@ts-ignore
        document?.getElementById(tableId)?.scrollIntoView();
      }}
      rowsPerPageOptions={[]}
      labelDisplayedRows={({ from, to, count }) =>
        `${from}–${to} из ${count !== -1 ? count : `больше чем ${to}`}`
      }
    />
  );

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <ProductTypeSelect
            productTypeId={productType?.id}
            label="Изменить тип выбранных продуктов"
            onSave={handleChangeProductType}
            disabled={!selectItems.items.size}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          {withStock && (
            <FormControl fullWidth>
              <InputLabel id="stockSelect-label" margin="dense">
                Наличие
              </InputLabel>
              <Select
                labelId="stockSelect-label"
                id="stockSelect"
                variant="outlined"
                value={inStockFilter}
                onChange={(e) =>
                  setInStockFilter(e.target.value as InStockFilter)
                }
              >
                <MenuItem
                  //@ts-ignore
                  value=""
                >
                  Не выбрано
                </MenuItem>
                <MenuItem value={InStockFilter.bonjour}>
                  {InStockFilter.bonjour}
                </MenuItem>
                <MenuItem value={InStockFilter.instock}>
                  {InStockFilter.instock}
                </MenuItem>
              </Select>
            </FormControl>
          )}
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <FormControlLabel
              control={
                <Checkbox
                  tabIndex={-1}
                  sx={{ p: 2 }}
                  disabled={!prouctsWithoutCharacteristics?.length}
                  checked={products?.length === selectItems.items.size}
                  onChange={() =>
                    products &&
                    selectItems.setItems(
                      selectItems.items.size === products?.length
                        ? []
                        : prouctsWithoutCharacteristics
                    )
                  }
                />
              }
              label={
                selectItems.items.size
                  ? `${selectItems.items.size} ${formattingService.declOfNum([
                      "продукт",
                      "продукта",
                      "продуктов",
                    ])(selectItems.items.size)}`
                  : null
              }
            />
          </Paper>
        </Grid>
        {Boolean(filters) && (
          <Grid item xs={12}>
            {filters}
          </Grid>
        )}
        <Grid item xs={12}>
          <Paper>
            <WithLoading
              loading={loading || requestBonjourInStockProducts.isLoading || requestAussInStockProducts.isLoading}
              rows={3}
              height={90}
            >
              {pagination}
              <Table size="small" id={tableId}>
                <TableBody>
                  {products?.map((product, index) => (
                    <TableRow
                      key={product.id}
                      hover
                      sx={{
                        opacity:
                        !inStockFilter || inStockProducts?.includes(product.id) 
                            ?  1
                            : 0.2,
                      }}
                    >
                      <TableCell>
                        <Box>
                          <Box sx={{ display: "flex" }}>
                            <Box>
                              <Checkbox
                                disabled={Boolean(
                                  product?.characteristics?.length
                                )}
                                tabIndex={-1}
                                sx={{ p: 2 }}
                                checked={selectItems.items.has(product.id)}
                                onChange={() => selectItems.setItem(product)}
                              />
                            </Box>
                            <Box sx={{ width: "100%" }}>
                              <ProductPreviewWithEdit
                                productType={productType}
                                product={product}
                                index={index}
                                count={products?.length || 0}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {pagination}
            </WithLoading>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
