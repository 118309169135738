import { addQueryParams } from "../api";

export const DADATA_TOKEN = process.env.REACT_APP_DADATA_TOKEN;

const CRM_HOST = process.env.REACT_APP_CRM_HOST;

const APP_API_HOST = process.env.REACT_APP_API_HOST;

interface GetRouteParams {
  id?: number | string;
  query?: { [key: string | number]: string | number | null };
}

const publicApi = (url: string) => `${APP_API_HOST}/public_v1${url}`;
const privateApi = (url: string) => `${APP_API_HOST}/v1${url}`;

const getCrmUrl = (url: string) => `${CRM_HOST}${url}`;

export const apiRoutesMap = {
  reviews: {
    getRoute: ({ query }: GetRouteParams) =>
      privateApi(addQueryParams({ href: "/reviews", query })),
  },
  review: {
    getRoute: (params: GetRouteParams) => privateApi(`/reviews/${params.id}`),
  },
  productClearColorImage: {
    getRoute: (params: GetRouteParams) => privateApi(`/products/${params.id}/clear_color_image`),
  },
};

const getAppPrefix = (url: string) => url;

export const routesMap = {
  reviews: {
    path: getAppPrefix("/reviews"),
    getTitle: () => "Отзывы",
    getRoute: () => getAppPrefix("/reviews"),
  },
};

export const crmRoutesMap = {
  user: {
    getRoute: (id?: string | number) => getCrmUrl(`/users/${id}`),
  },
}
