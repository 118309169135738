import React from "react";
import { Link as MuiLink, LinkTypeMap } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { OverridableComponent } from "@mui/material/OverridableComponent";

export const Link: OverridableComponent<
  LinkTypeMap<{ blank?: boolean; onClick?: () => any }>
> = ({ href, children, onClick, blank, ...props }: any) => {
  if (!onClick && !href) {
    return (
      <MuiLink
        sx={(theme) => ({
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.fontSize,
          textAlign: "left",
        })}
        component="button"
        {...props}
      >
        {children}
      </MuiLink>
    );
  }
  if (onClick) {
    return (
      <MuiLink
        sx={(theme) => ({
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.fontSize,
          textAlign: "left",
        })}
        component="button"
        onClick={onClick}
        {...props}
      >
        {children}
      </MuiLink>
    );
  }
  return (
    <MuiLink
      sx={(theme) => ({
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
        textAlign: "left",
      })}
      component={blank ? "a" : RouterLink}
      onClick={onClick}
      to={blank ? undefined : href}
      href={href}
      {...props}
      target={blank ? "_blank" : undefined}
    >
      {children}
    </MuiLink>
  );
};
