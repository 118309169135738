import { useState } from "react";
import swr, { mutate, SWRConfiguration } from "swr";

import {
  User,
  ProductType,
  Product,
  ProductTypeAttribute,
  ProductTypeAttributeType,
  Brand,
  Image,
  Series,
  UndefinedBrand,
  UndefinedManufacturer,
  Manufacturer,
  Alias,
  MetaTagData,
} from "src/shared/api";

export const removeEmptyProps = (query: any) =>
  Object.fromEntries(Object.entries(query).filter(([_, v]) => v != null));

const fetcher = (url: string, options: RequestInit = {}) =>
  fetch(url, { ...options, credentials: "include" })
    .then((r) => r.json())
    .catch((e) => {
      console.error(e);
      return null;
    });

function request<T>(
  key: any[] | null,
  fetcher: any,
  options: SWRConfiguration = { revalidateOnFocus: false }
) {
  return swr<T>(key, fetcher, options);
}

const publicApi = (url: string) =>
  `${process.env.REACT_APP_API_HOST}/public_v1${url}`;
const privateApi = (url: string) =>
  `${process.env.REACT_APP_API_HOST}/v1${url}`;
const bonjourPrivateApi = (url: string) => `https://api.bonjour-dv.ru/v1${url}`;
const aussPublicApi = (url: string) => `https://api.instock-dv.ru/v1_api${url}`;

type ListResponse<T> = { data: T[]; total_count: number };

export const apiService = {
  userMe: () => fetcher(publicApi("/me")),

  //productType
  productTypes: () => fetcher(privateApi("/products-types")),
  productType: (id: number | string) =>
    fetcher(privateApi(`/products-types/${id}`)),
  createProductType: (data: { name: string }) =>
    fetcher(privateApi("/products-types"), {
      method: "POST",
      body: JSON.stringify(data),
    }),
  changeProductType: (id: string | number, data: { name: string }) =>
    fetcher(privateApi(`/products-types/${id}`), {
      method: "PATCH",
      body: JSON.stringify(data),
    }),
  deleteProductType: (id: number | string) =>
    fetcher(privateApi(`/products-types/${id}`), { method: "DELETE" }),
  addProductsToType: (data: { typeId: string | number; products: number[] }) =>
    fetcher(privateApi(`/products-types/${data.typeId}/products`), {
      method: "POST",
      body: JSON.stringify(data.products),
    }),

  //attribute
  attributes: () => fetcher(privateApi("/attributes")),
  createAttribute: (data: { name: string; type: ProductTypeAttributeType }) =>
    fetcher(privateApi("/attributes"), {
      method: "POST",
      body: JSON.stringify(data),
    }),
  deleteAttribute: (id: number | string) =>
    fetcher(privateApi(`/attributes/${id}`), {
      method: "DELETE",
    }),
  deleteAttributeValue: (id: number | string) =>
    fetcher(privateApi(`/attributes/list_possible_values/${id}`), {
      method: "DELETE",
    }),
  addAttributeToProductType: (data: {
    productTypeId: string | number;
    attribute: { id: number };
  }) =>
    fetcher(privateApi(`/products-types/${data.productTypeId}/attributes`), {
      method: "POST",
      body: JSON.stringify(data.attribute),
    }),
  addValueToAttribute: (data: {
    attributeId: string | number;
    value: { value: string };
  }) =>
    fetcher(
      privateApi(`/attributes/${data.attributeId}/list_possible_values`),
      {
        method: "POST",
        body: JSON.stringify(data.value),
      }
    ),
  changeAttribute: (data: {
    attributeId: string | number;
    attribute: { name: string };
  }) =>
    fetcher(privateApi(`/attributes/${data.attributeId}`), {
      method: "PATCH",
      body: JSON.stringify(data.attribute),
    }),
  changeAttributeValue: (data: {
    valueId: string | number;
    value: { value: string };
  }) =>
    fetcher(privateApi(`/attributes/list_possible_values/${data.valueId}`), {
      method: "PATCH",
      body: JSON.stringify(data.value),
    }),
  deleteAttributeFromProductType: (data: {
    productTypeId: string | number;
    attributeId: string | number;
  }) =>
    fetcher(
      privateApi(
        `/products-types/${data.productTypeId}/attributes/${data.attributeId}`
      ),
      {
        method: "DELETE",
      }
    ),

  //product
  products: (query: any) =>
    fetcher(privateApi(`/products?${new URLSearchParams(query).toString()}`)),
  bonjourProducts: (query: any) =>
    fetcher(
      bonjourPrivateApi(`/products?${new URLSearchParams(query).toString()}`)
    ),
  aussInstockProducts: (data?: number[]) =>
    fetcher(aussPublicApi(`/available-products-ids`), {
      method: "POST",
      body: JSON.stringify(data),
    }),
  aussInstockMissingCategoriesProducts: () =>
    fetcher(aussPublicApi("/categories/missing_products?limit=10000")),
  product: (id: string | number) => fetcher(privateApi(`/products/${id}`)),
  productImages: (id: string | number) =>
    fetcher(privateApi(`/products/${id}/images`)),
  changeProduct: (data: { productId: number | string; value: any }) =>
    fetcher(privateApi(`/products/${data.productId}`), {
      method: "PATCH",
      body: JSON.stringify(data.value),
    }),
  changeProductCharacteristic: (data: {
    productId: number | string;
    attributeId: number | string;
    value: any;
  }) =>
    fetcher(
      privateApi(
        `/products/${data.productId}/characteristics/${data.attributeId}`
      ),
      {
        method: "PUT",
        body: JSON.stringify(data.value),
      }
    ),
  addImageToProduct: (data: { productId: string | number; images: any }) =>
    fetcher(privateApi(`/products/${data.productId}/images`), {
      method: "POST",
      body: data.images,
    }),
  changeProductImage: (data: {
    productId: string | number;
    imageId: string | number;
    image: Image;
  }) =>
    fetcher(privateApi(`/products/${data.productId}/images/${data.imageId}`), {
      method: "PATCH",
      body: JSON.stringify(data.image),
    }),

  //barnd
  brands: (query: any = {}) =>
    fetcher(
      privateApi(
        `/brands?include=aliases,manufacturer&${new URLSearchParams(
          query
        ).toString()}`
      )
    ),
  brand: (id: number | string) => fetcher(privateApi(`/brands/${id}`)),
  addLogoToBrand: (data: { brandId: string | number; images: any }) =>
    fetcher(privateApi(`/brands/${data.brandId}/logo`), {
      method: "POST",
      body: data.images,
    }),
  undefinedBrands: () => fetcher(privateApi("/undefinedBrands")),
  createBrand: (data: { name: string; manufacturer: number | null }) =>
    fetcher(privateApi("/brands"), {
      method: "POST",
      body: JSON.stringify(data),
    }),
  changeBrand: (data: {
    brandId: number | string;
    brand: {
      name?: string | null;
      adapted_name?: string | null;
      manufacturer?: number | null;
      meta_tag_data?: MetaTagData;
      title?: string | null;
      description?: string | null;
      highlighted?: boolean;
    };
  }) =>
    fetcher(privateApi(`/brands/${data.brandId}`), {
      method: "PUT",
      body: JSON.stringify(data.brand),
    }),
  addAliasToBrand: (data: { brandId: number | string; alias: Alias }) =>
    fetcher(privateApi(`/brands/${data.brandId}/aliases`), {
      method: "POST",
      body: JSON.stringify(data.alias),
    }),

  //manufacturer
  manufacturers: () =>
    fetcher(privateApi("/manufacturers?include=alias,brand")),
  undefinedManufacturers: () => fetcher(privateApi("/undefinedManufacturers")),
  addAliasToManufacturer: (data: {
    manufacturerId: number | string;
    alias: { id: number };
  }) =>
    fetcher(privateApi(`/manufacturers/${data.manufacturerId}/aliases`), {
      method: "POST",
      body: JSON.stringify(data.alias),
    }),
  createManufacturer: (data: { name: string }) =>
    fetcher(privateApi("/manufacturers"), {
      method: "POST",
      body: JSON.stringify(data),
    }),
  changeManufacturer: (data: {
    manufacturerId: number | string;
    manufacturer: { name: string };
  }) =>
    fetcher(privateApi(`/manufacturers/${data.manufacturerId}`), {
      method: "PUT",
      body: JSON.stringify(data.manufacturer),
    }),

  //alias
  deleteAlias: (aliasId: number | string) =>
    fetcher(privateApi(`/aliases/${aliasId}`), { method: "DELETE" }),

  //series
  series: (query: any = {}) =>
    fetcher(
      publicApi(`/product-series?${new URLSearchParams(query).toString()}`)
    ),
  createSeries: (data: { name: string | number; brand: { id: number } }) =>
    fetcher(privateApi("/product-series"), {
      method: "POST",
      body: JSON.stringify(data),
    }),
};

type RequestHookParams<T> = { variables?: T; skip?: boolean };

type ListRequest<T> = {
  limit?: number | string | null;
  offset?: number | string | null;
} & T;

export function useGetMe() {
  const response = request<User>([apiService.userMe.name], apiService.userMe);

  return {
    ...response,
    isLoading: (!response.data && !response.error) || response.isValidating,
  };
}

export function useGetProductTypes(params?: RequestHookParams<void>) {
  const key = params?.skip ? null : [apiService.productTypes.name];

  const response = request<ProductType[]>(key, apiService.productTypes);

  const refetch = () => mutate(key);

  return {
    ...response,
    isLoading: (!response.data && !response.error) || response.isValidating,
    refetch,
  };
}

export function useGetProductType(
  params: RequestHookParams<{ id: string | number }>
) {
  const key = params.skip
    ? null
    : [apiService.productType.name, params?.variables?.id];

  const response = request<ProductType>(key, () =>
    apiService.productType(params?.variables?.id as number)
  );

  const refetch = () => mutate(key);

  return {
    ...response,
    isLoading: (!response.data && !response.error) || response.isValidating,
    refetch,
  };
}

export function useGetProduct(
  params: RequestHookParams<{ id: string | number }>
) {
  const key = params.skip
    ? null
    : [apiService.product.name, params?.variables?.id];

  const response = request<Product>(key, () =>
    apiService
      .product(params?.variables?.id as number)
      .then((res) => res.product)
  );

  const refetch = () => mutate(key);

  return {
    ...response,
    isLoading: params.skip
      ? false
      : (!response.data && !response.error) || response.isValidating,
    refetch,
  };
}

export function useGetProducts(
  params: RequestHookParams<
    ListRequest<{
      type?: string | number | null;
      image?: string | number | null;
      brand?: string | number | null;
      name?: string | number | null;
      manufacturer?: string | number | null;
      barcodes?: string | number | null;
      barcode?: string | number | null;
      binding?: string | null;
      ids?: string | null;
    }>
  >
) {
  const key = params.skip
    ? null
    : [
        apiService.products.name,
        params?.variables?.type,
        params?.variables?.limit,
        params?.variables?.offset,
        params?.variables?.image,
        params?.variables?.brand,
        params?.variables?.manufacturer,
        params?.variables?.name,
        params?.variables?.binding,
        params?.variables?.barcodes,
        params?.variables?.barcode,
        params?.variables?.ids,
      ];

  const response = request<ListResponse<Product>>(key, () =>
    apiService
      .products(removeEmptyProps(params.variables))
      .then((res) => ({ data: res.products, total_count: res.total_count }))
  );

  const refetch = () => mutate(key);

  return {
    ...response,
    isLoading: (!response.data && !response.error) || response.isValidating,
    refetch,
  };
}

export function useGetAttributes(
  params?: RequestHookParams<ListRequest<{} | void>>
) {
  const key = params?.skip ? null : [apiService.attributes.name];

  const response = request<ProductTypeAttribute[]>(key, apiService.attributes);

  const refetch = () => mutate(key);

  return {
    ...response,
    isLoading: (!response.data && !response.error) || response.isValidating,
    refetch,
  };
}

export function requsetGetInstockMissingCategoriesProducts() {
  return apiService.aussInstockMissingCategoriesProducts();
}

export function useGetBrands(
  params?: RequestHookParams<{
    manufacturer?: number | string | null;
    with_logo?: string | number | boolean | null;
    highlighted?: string | number | boolean | null;
  }>
) {
  const key = params?.skip
    ? null
    : [
        apiService.brands.name,
        params?.variables?.manufacturer,
        params?.variables?.with_logo,
        params?.variables?.highlighted,
      ];

  const response = request<Brand[]>(key, () =>
    apiService
      .brands(removeEmptyProps(params?.variables || {}))
      .then((res) => res.brands)
  );

  const refetch = () => mutate(key);

  return {
    ...response,
    isLoading: (!response.data && !response.error) || response.isValidating,
    refetch,
  };
}

export function useGetManufacturers() {
  const key = [apiService.manufacturers.name];

  const response = request<Manufacturer[]>(key, () =>
    apiService.manufacturers().then((res) => res.manufacturers)
  );

  const refetch = () => mutate(key);

  return {
    ...response,
    isLoading: (!response.data && !response.error) || response.isValidating,
    refetch,
  };
}

export function useGetProductImages(
  params: RequestHookParams<
    ListRequest<{
      id: string | number;
    }>
  >
) {
  const key = params.skip
    ? null
    : [apiService.productImages.name, params?.variables?.id];

  const response = request<Image[]>(key, () =>
    apiService
      .productImages(params?.variables?.id as number)
      .then((res) => res.images)
  );

  const refetch = () => mutate(key);

  return {
    ...response,
    isLoading: (!response.data && !response.error) || response.isValidating,
    refetch,
  };
}

export const addImagesToProduct = (productId: number, data: Array<File>) => {
  const images = new FormData();
  data.forEach((file, index) => images.append(`image[${index}]`, file));
  return apiService.addImageToProduct({ productId, images });
};

export function useGetProductSeries(
  params: RequestHookParams<
    ListRequest<{
      brand: string | number;
    }>
  >
) {
  const key = params.skip
    ? null
    : [apiService.series.name, params?.variables?.brand];

  const response = request<Series[]>(key, () =>
    apiService.series(params.variables)
  );

  const refetch = () => mutate(key);

  return {
    ...response,
    isLoading: (!response.data && !response.error) || response.isValidating,
    refetch,
  };
}

export function useGetUndefinedBrands() {
  const key = [apiService.undefinedBrands.name];

  const response = request<UndefinedBrand[]>(key, () =>
    apiService.undefinedBrands().then((res) => res.undefinedBrands)
  );

  const refetch = () => mutate(key);

  return {
    ...response,
    isLoading: (!response.data && !response.error) || response.isValidating,
    refetch,
  };
}

export function useGetUndefinedManufacturers() {
  const key = [apiService.undefinedManufacturers.name];

  const response = request<UndefinedManufacturer[]>(key, () =>
    apiService
      .undefinedManufacturers()
      .then((res) => res.undefinedManufacturers)
  );

  const refetch = () => mutate(key);

  return {
    ...response,
    isLoading: (!response.data && !response.error) || response.isValidating,
    refetch,
  };
}

export function useGetBrand(
  params: RequestHookParams<
    ListRequest<{
      id: string | number;
    }>
  >
) {
  const key = params.skip
    ? null
    : [apiService.brand.name, params?.variables?.id];

  const response = request<Brand>(key, () =>
    apiService.brand(params?.variables?.id as string)
  );

  const refetch = () => mutate(key);

  return {
    ...response,
    isLoading: (!response.data && !response.error) || response.isValidating,
    refetch,
  };
}

export const addLogoToBrand = (brandId: string | number, images: any) =>
  apiService.addLogoToBrand({ brandId, images });

export function useGetManufacturer(
  params: RequestHookParams<
    ListRequest<{
      id: string | number;
    }>
  >
) {
  const key = params.skip
    ? null
    : [apiService.manufacturers.name, params?.variables?.id];

  const response = request<Manufacturer>(key, () =>
    apiService
      .manufacturers()
      .then((res) =>
        res.manufacturers.find(
          (manufacturer: Manufacturer) =>
            manufacturer.id === parseInt(params?.variables?.id as string, 10)
        )
      )
  );

  const refetch = () => mutate(key);

  return {
    ...response,
    isLoading: (!response.data && !response.error) || response.isValidating,
    refetch,
  };
}

export function useGetBonjourInStock(
  params: RequestHookParams<{
    ids?: string;
    type?: string | number;
  }>
) {
  const [isLoading, setLoading] = useState(false);
  const key = params.skip
    ? null
    : [
        apiService.bonjourProducts.name,
        params?.variables?.type,
        params?.variables?.ids,
      ];

  const response = request<number[]>(key, () => {
    setLoading(true);
    return apiService
      .bonjourProducts(
        removeEmptyProps({ ...params.variables, in_stock: true })
      )
      .then((res) => {
        setLoading(false);
        return res.products?.map((item: any) => item.id);
      })
      .catch(() => {
        setLoading(false);
        return null;
      });
  });

  const refetch = () => mutate(key);

  return {
    ...response,
    isLoading,
    refetch,
  };
}

export function useGetAussInStock(
  params: RequestHookParams<{ ids: number[] }>
) {
  const [isLoading, setLoading] = useState(false);
  const key = params.skip
    ? null
    : [apiService.aussInstockProducts.name, params?.variables?.ids];

  const response = request<number[]>(key, () => {
    setLoading(true);
    return apiService
      .aussInstockProducts(params?.variables?.ids)
      .then((res) => {
        setLoading(false);
        return res;
      })
      .catch(() => {
        setLoading(false);
        return null;
      });
  });

  const refetch = () => mutate(key);

  return {
    ...response,
    isLoading,
    refetch,
  };
}

export interface CharacteristicSuggestion {
  value: number;
  adapt_value: string;
}

export function useGetCharacteristicSuggestions(
  params: RequestHookParams<ListRequest<{ unit: string; value: number }>>
) {
  let data: CharacteristicSuggestion[] = [];

  if (params.variables?.value && params.variables?.unit === "мл") {
    data.push({
      value: params.variables.value * 1000,
      adapt_value: `${params.variables.value}л`,
    });
    if (
      params.variables.value > 9 &&
      params.variables.value %
        Math.pow(10, `${params.variables.value}`.length - 1)
    ) {
      data.push({
        value:
          params.variables.value *
          Math.pow(100, `${params.variables.value}`.length - 1),
        adapt_value: `${
          params.variables.value /
          Math.pow(10, `${params.variables.value}`.length - 1)
        }л`,
      });
    }
    data.push({
      value: params.variables.value,
      adapt_value: `${params.variables.value}мл`,
    });
  }

  if (params.variables?.value && params.variables?.unit === "мм") {
    data.push({
      value: params.variables.value * 1000,
      adapt_value: `${params.variables.value}м`,
    });
    if (
      params.variables.value > 9 &&
      params.variables.value %
        Math.pow(10, `${params.variables.value}`.length - 1)
    ) {
      data.push({
        value:
          params.variables.value *
          Math.pow(100, `${params.variables.value}`.length - 1),
        adapt_value: `${
          params.variables.value /
          Math.pow(10, `${params.variables.value}`.length - 1)
        }м`,
      });
    }
    data.push({
      value: params.variables.value * 10,
      adapt_value: `${params.variables.value}см`,
    });
    data.push({
      value: params.variables.value,
      adapt_value: `${params.variables.value}мм`,
    });
  }

  if (params.variables?.value && params.variables?.unit === "мг") {
    data.push({
      value: params.variables.value * 1000000,
      adapt_value: `${params.variables.value}кг`,
    });
    if (
      params.variables.value > 9 &&
      params.variables.value %
        Math.pow(10, `${params.variables.value}`.length - 1)
    ) {
      data.push({
        value:
          params.variables.value *
          Math.pow(100000, `${params.variables.value}`.length - 1),
        adapt_value: `${
          params.variables.value /
          Math.pow(10, `${params.variables.value}`.length - 1)
        }кг`,
      });
    }
    data.push({
      value: params.variables.value * 1000,
      adapt_value: `${params.variables.value}гр`,
    });
    if (
      params.variables.value > 9 &&
      params.variables.value %
        Math.pow(10, `${params.variables.value}`.length - 1)
    ) {
      data.push({
        value:
          params.variables.value *
          Math.pow(100, `${params.variables.value}`.length - 1),
        adapt_value: `${
          params.variables.value /
          Math.pow(10, `${params.variables.value}`.length - 1)
        }гр`,
      });
    }
    data.push({
      value: params.variables.value,
      adapt_value: `${params.variables.value}мг`,
    });
  }

  if (params.variables?.value && params.variables?.unit === "мВт") {
    data.push({
      value: params.variables.value * 1000000,
      adapt_value: `${params.variables.value}кВт`,
    });
    if (
      params.variables.value > 9 &&
      params.variables.value %
        Math.pow(10, `${params.variables.value}`.length - 1)
    ) {
      data.push({
        value:
          params.variables.value *
          Math.pow(100000, `${params.variables.value}`.length - 1),
        adapt_value: `${
          params.variables.value /
          Math.pow(10, `${params.variables.value}`.length - 1)
        }кВт`,
      });
    }
    data.push({
      value: params.variables.value * 1000,
      adapt_value: `${params.variables.value}Вт`,
    });
    if (
      params.variables.value > 9 &&
      params.variables.value %
        Math.pow(10, `${params.variables.value}`.length - 1)
    ) {
      data.push({
        value:
          params.variables.value *
          Math.pow(100, `${params.variables.value}`.length - 1),
        adapt_value: `${
          params.variables.value /
          Math.pow(10, `${params.variables.value}`.length - 1)
        }Вт`,
      });
    }
    data.push({
      value: params.variables.value,
      adapt_value: `${params.variables.value}мВт`,
    });
  }

  return {
    data: { data },
  };
}
