import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
} from "@mui/material";
import { Box } from "@mui/system";
import { useGetProductSeries } from "src/services/apiService";
import { WithLoading, AddSeriesDialog } from "src/components/common";

//shared
import { Brand, Series } from "src/shared/api";

interface Props {
  brand: Brand;
  seriesId?: number | null;
  onSelect: (series: Series) => any;
}

export const ProductSeriesSelect: React.FunctionComponent<Props> = ({
  brand,
  onSelect,
  seriesId,
}) => {
  const [name, setName] = useState("");
  const requset = useGetProductSeries({ variables: { brand: brand?.id as number }, skip: !brand });

  return (
    <Box>
      <WithLoading loading={requset.isLoading}>
        <List sx={{ mt: 2 }} disablePadding>
          {requset.data
            ?.sort((a, b) => (a.name > b.name ? 1 : -1))
            ?.map((series, index) => (
              <ListItem
                key={series.id}
                dense
                divider={requset.data && index + 1 < requset.data?.length}
                disablePadding
              >
                <ListItemButton onClick={() => onSelect(series)}>
                  <ListItemIcon>
                    <Radio checked={series.id === seriesId} />
                  </ListItemIcon>
                  <ListItemText primary={series.name} />
                </ListItemButton>
              </ListItem>
            ))}
        </List>
      </WithLoading>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
        <AddSeriesDialog brand={brand} onResponse={requset.refetch} />
      </Box>
    </Box>
  );
};
