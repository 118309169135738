import { EditOutlined } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
} from "@mui/material";
import React, { useState } from "react";
import { Product } from "src/components/common";
import { useGetProduct } from "src/services/apiService";

//shared
import { Product as IProduct } from "src/shared/api";

interface Props {
  product: IProduct;
  children?: any;
  onSave?: () => any;
}

export const ProductDialog: React.FunctionComponent<Props> = ({
  product,
  children,
  onSave,
}) => {
  const [open, setOpen] = useState(false);

  const request = useGetProduct({
    variables: { id: product.id as number },
    skip: !open,
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSave = () => {
    request.refetch();
    if (onSave) onSave();
  };

  return (
    <>
      {children ? (
        //@ts-ignore
        React.cloneElement(children, { onClick: handleOpen })
      ) : (
        <Fab
          size="small"
          onClick={handleOpen}
          color="secondary"
          title="Добавить значение"
        >
          <EditOutlined />
        </Fab>
      )}
      <Dialog
        onClose={handleClose}
        open={open}
        fullWidth
        keepMounted={false}
        maxWidth="lg"
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <Product
          product={request.data}
          loading={request.isLoading}
          onSave={handleSave}
          template={({ title, content, actions }) => (
            <>
              <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
              <DialogContent
                sx={{ width: "100%" }}
                dividers
                id="scroll-dialog-description"
              >
                {content}
              </DialogContent>
              <DialogActions>{actions}</DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};
