import React, { useState, useEffect } from "react";
import { WithLoading } from "src/components/common";
import clsx from "clsx";
import Color from "color";
import {
  IconButton,
  Toolbar,
  Typography,
  Table as MuiTable,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableCellBaseProps,
  TableBody,
  TablePagination,
  TableFooter,
  Box,
} from "@mui/material";
import { RefreshOutlined } from "@mui/icons-material";
import { grey } from "@mui/material/colors";

interface Header {
  title: string;
  align?: "left" | "right" | "center";
  extract?: (item: any, index?: number) => React.ReactNode;
  component?: React.ElementType<TableCellBaseProps>;
  scope?: TableCellBaseProps["scope"];
  warning?: (item: any) => boolean;
  summary?: React.ReactNode;
  onClick?: (e: React.SyntheticEvent, item: any) => any;
}

interface Props {
  onChange?: (params: { page: number; rowsPerPage: number }) => any;
  onFetch?: () => any;
  loading: boolean;
  dense?: boolean;
  withPagination?: boolean;
  totalCount: number;
  title?: string;
  onClickRow?: (item: any) => any;
  rowStyle?: (item: any) => any;
  headers: Array<Header | null>;
  data?: any[] | null;
  limit: number | null;
  offset?: number | null;
  withSummary?: boolean;
  actions?: ((params: { selectedItems: any }) => React.ReactNode)[];
}

type typeState = {
  rowsPerPage: number;
  page: number;
};

const defaultLimit = 23;

export const Table: React.FunctionComponent<Props> = ({
  title,
  onFetch,
  onChange,
  loading,
  data = [],
  totalCount = 0,
  onClickRow,
  headers: providedHeaders,
  actions,
  withSummary,
  withPagination = true,
  dense = "small",
  rowStyle,
  limit: providedLimit = 23,
  offset: providedOffset = 0,
}) => {
  //   const classes = useStyles({});
  const classes: any = {};
  const [tableId, setTableId] = useState("table_");

  useEffect(() => {
    setTableId(`table_${(Math.random() + 1).toString(36).substring(7)}`);
  }, []);

  const [state, changeState] = useState<typeState>({
    rowsPerPage: providedLimit || defaultLimit,
    page: 0,
  });

  const [selectedItems, setSelectedItems] = useState<typeof data>([]);

  const setState = (data: Partial<typeState> = {}) => {
    const nextState = { ...state, ...data };

    if (onChange) {
      onChange(nextState);
    }

    changeState(nextState);
  };

  useEffect(() => {
    changeState({
      ...state,
      rowsPerPage: providedLimit || defaultLimit,
      page:
        typeof providedOffset === "number" && typeof providedLimit === "number"
          ? providedOffset / providedLimit
          : 0,
    });
  }, [providedLimit, providedOffset]);

  const headers = providedHeaders.filter((item) => item) as Header[];

  const pagination = withPagination && (
    <TableFooter>
      <TableRow>
        <TablePagination
          colSpan={headers?.length + 1}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} из ${count !== -1 ? count : `больше чем ${to}`}`
          }
          labelRowsPerPage="элементов на странице"
          rowsPerPageOptions={
            Array.from(
              new Set(
                [
                  defaultLimit,
                  providedLimit || defaultLimit,
                  totalCount > 201 ? 200 : totalCount,
                ].sort((a, b) => ((a as number) - b) as number)
              )
            ) as number[]
          }
          count={totalCount || 1}
          rowsPerPage={state.rowsPerPage}
          page={state.page}
          onRowsPerPageChange={(e) =>
            setState({ rowsPerPage: parseInt(e.target.value, 10) })
          }
          onPageChange={(e, value) => {
            setState({ page: value });
            //@ts-ignore
            document?.getElementById(tableId)?.scrollIntoView();
          }}
        />
      </TableRow>
    </TableFooter>
  );

  return (
    <div className={classes.root}>
      {Boolean(title || onFetch) && (
        <Toolbar>
          <Typography className={classes.title} variant="h6" component="div">
            {title}
          </Typography>

          {actions?.map((action, index) => (
            <React.Fragment key={index}>
              {action({ selectedItems })}
            </React.Fragment>
          ))}
          {Boolean(onFetch) && (
            <IconButton
              onClick={() => {
                if (onFetch) {
                  onFetch();
                }
              }}
            >
              <RefreshOutlined />
            </IconButton>
          )}
        </Toolbar>
      )}
      <WithLoading loading={loading} rows={5} height={37}>
        <MuiTable
          aria-label="table"
          size={dense ? "small" : "medium"}
          id={tableId}
        >
          {pagination}
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: grey[500] }}>#</TableCell>
              {headers.map((header) => {
                if (!header.extract) return null;
                if (header.title === "checkbox") {
                  return (
                    <TableCell key={header.title}>
                      <Checkbox
                        checked={Boolean(
                          data?.length === selectedItems?.length
                        )}
                        onChange={() =>
                          data?.length !== selectedItems?.length
                            ? setSelectedItems(data)
                            : setSelectedItems([])
                        }
                      />
                    </TableCell>
                  );
                }
                return (
                  <TableCell key={header.title} align={header.align || "left"}>
                    {header.title}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((item: any, index) => (
              <TableRow
                hover
                key={item.id}
                onClick={(e) => (onClickRow ? onClickRow(item) : null)}
                style={rowStyle ? rowStyle(item) : null}
              >
                <TableCell sx={{ color: grey[500] }}>{index + 1}</TableCell>
                {headers.map((header) => {
                  if (!header.extract) return null;
                  if (header.title === "checkbox") {
                    return (
                      <TableCell
                        key={header.title}
                        align={header.align || "left"}
                      >
                        <Checkbox
                          checked={Boolean(
                            selectedItems?.find(
                              (selectedItem) => selectedItem.id === item.id
                            )
                          )}
                          onChange={() =>
                            selectedItems &&
                            selectedItems.find(
                              (selectedItem) => selectedItem.id === item.id
                            )
                              ? setSelectedItems(
                                  selectedItems.filter(
                                    (selectedItem) =>
                                      selectedItem.id !== item.id
                                  )
                                )
                              : selectedItems &&
                                setSelectedItems([...selectedItems, item])
                          }
                        />
                      </TableCell>
                    );
                  }
                  return (
                    <TableCell
                      onClick={(e) =>
                        header.onClick ? header.onClick(e, item) : null
                      }
                      align={header.align || "left"}
                      key={header.title}
                      component={header.component}
                      scope={header.scope}
                      className={clsx(
                        header.warning &&
                          header.warning(item) &&
                          classes.warning
                      )}
                    >
                      {header.extract(item, index)}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
            {withSummary && (
              <TableRow>
                <TableCell />
                {headers.map((header) => (
                  <TableCell key={header.title} align={header.align || "left"}>
                    {header.summary}
                  </TableCell>
                ))}
              </TableRow>
            )}
          </TableBody>
          {pagination}
        </MuiTable>
      </WithLoading>
    </div>
  );
};
