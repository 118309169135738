import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Check, SearchOutlined } from "@mui/icons-material";
import React, { useEffect, useMemo, useState } from "react";
import { routesMap } from "src/routes";
import {
  useGetProductType,
  useGetProducts,
  apiService,
  removeEmptyProps,
} from "src/services/apiService";
import {
  WithLoading,
  WithConfirm,
  AddAttributeToProductTypeDialog,
  Attribute,
  ProductPreviewWithEdit,
  characteristcEditInputWidth,
  ProductTypeSelect,
  CheckEnLabel,
  Products,
  ProductsFilters,
} from "src/components/common";
import { Close } from "@mui/icons-material";

//shared
import { ProductType, ProductTypeAttribute } from "src/shared/api";
import { usePageTitle, useRouter } from "src/shared/hooks";

const PossibleProducts = ({
  productType,
  onSave,
}: {
  productType?: ProductType | null;
  onSave: () => any;
}) => {
  const router = useRouter();
  const [possibleProductsName, setPossibleProductsName] = useState("");
  const [nextNameQuery, setNextNameQuery] = useState("");
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (productType) {
      setPossibleProductsName(productType.name);
      setNextNameQuery(productType.name);
    }
  }, [productType]);

  const variables = {
    possibleName: nextNameQuery,
    possibleType: 0,
    possibleOffset:
      parseInt(router.query.get("possibleOffset") as string, 10) || 0,
    possibleLimit: 50,
  };

  const requestPossibleProducts = useGetProducts({
    variables: {
      name: variables.possibleName,
      type: variables.possibleType,
      offset: variables.possibleOffset,
      limit: variables.possibleLimit,
    },
    skip: !nextNameQuery,
  });

  const handleSave = () => {
    onSave();
    requestPossibleProducts.refetch();
  };
  const handleShow = () => setShow(true);

  const push = (query: any) =>
    router.push(
      `${routesMap.productType.getRoute(productType?.id)}?${new URLSearchParams(
        //@ts-ignore
        removeEmptyProps(query)
      )}`
    );

  const handleChangePage = (page: number) =>
    push({
      ...variables,
      possibleOffset: page * variables.possibleLimit,
    });

  const products = requestPossibleProducts.data?.data || [];

  return (
    <>
      <Typography variant="h5">
        Возможные продукты для типа (
        {requestPossibleProducts.data?.total_count || 0})
      </Typography>
      {!show && (
        <Button onClick={handleShow} variant="contained" sx={{ mt: 2 }}>
          Показать
        </Button>
      )}
      {show && (
        <>
          <Box mt={2}>
            <TextField
              label="Название"
              fullWidth
              value={possibleProductsName}
              onChange={(e) => setPossibleProductsName(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setNextNameQuery(possibleProductsName);
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      color="secondary"
                      disabled={!possibleProductsName}
                      onClick={() => setNextNameQuery(possibleProductsName)}
                    >
                      <SearchOutlined />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box mt={2}>
            <Products
              productType={productType}
              products={products}
              count={requestPossibleProducts.data?.total_count || 0}
              limit={variables.possibleLimit}
              offset={variables.possibleOffset}
              loading={requestPossibleProducts.isLoading}
              onSave={handleSave}
              onChangePage={handleChangePage}
            />
          </Box>
        </>
      )}
    </>
  );
};

interface Props {}

const ProductTypeData = ({
  productType,
  isDeleteDisabled,
  onSave,
}: {
  productType: ProductType;
  isDeleteDisabled: boolean;
  onSave: () => any;
}) => {
  const [name, setName] = useState("");
  const router = useRouter();

  useEffect(() => {
    setName(productType.name);
  }, [productType]);

  const handleDelete = () => {
    apiService.deleteProductType(productType.id).then();
  };

  const handleDeleteProductAttribute = (item: ProductTypeAttribute) => {
    apiService
      .deleteAttributeFromProductType({
        productTypeId: productType.id,
        attributeId: item.id,
      })
      .then(() => {
        router.push(routesMap.productTypes.getRoute());
      });
  };

  const handleSave = () => {
    apiService.changeProductType(productType.id, { name }).then(onSave);
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={4}>
        <Box sx={{ height: "100%" }}>
          <TextField
            value={name}
            onChange={(e) => setName(e.target.value)}
            label="Название"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CheckEnLabel>{name}</CheckEnLabel>
                  {name === productType.name && (
                    <Icon color="primary" title="Сохранено">
                      <Check />
                    </Icon>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box sx={{ height: "100%" }}>
          <Typography variant="subtitle1">Атрибуты</Typography>
          {Boolean(productType.attributes?.length) && (
            <Box mt={1}>
              {productType.attributes
                ?.sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((attribute) => (
                  <Box key={attribute.id} display="flex" alignItems="center">
                    <Attribute
                      attribute={attribute}
                      key={attribute.id}
                      onSave={onSave}
                      disableEdit
                    />
                    <WithConfirm
                      onConfirm={() => handleDeleteProductAttribute(attribute)}
                      confirmTitle="Удалить атрибут из типа продукта"
                    >
                      <IconButton
                        color="error"
                        title="Удалить атрибут из типа продукта"
                      >
                        <Close />
                      </IconButton>
                    </WithConfirm>
                  </Box>
                ))}
            </Box>
          )}
          <Box
            mt={1}
            sx={{ mt: 1, display: "flex", justifyContent: "flex-end" }}
          >
            {productType && (
              <AddAttributeToProductTypeDialog
                productType={productType}
                onResponse={onSave}
              />
            )}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box sx={{ height: "100%" }}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              color="secondary"
              onClick={handleSave}
              variant="contained"
              sx={{ mr: 2 }}
            >
              Сохранить
            </Button>
            <WithConfirm
              confirmTitle="Удалить"
              onConfirm={handleDelete}
              disabled={isDeleteDisabled}
            >
              <Button
                color="error"
                disabled={isDeleteDisabled}
                variant="outlined"
              >
                Удалить
              </Button>
            </WithConfirm>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export const ProductTypePage: React.FunctionComponent<Props> = ({}) => {
  const router = useRouter();

  const pageTitle = usePageTitle();

  const requestType = useGetProductType({
    variables: { id: router.params.productTypeId as string },
  });

  const [arttibuteFilter, setArttibuteFilter] = useState<number | null>(null);

  const variablesRequestProducts = {
    type: router.params.productTypeId as string,
    offset: parseInt(router.query.get("offset") as string, 10) || 0,
    limit: 50,
    brand: router.query.get("brand"),
    manufacturer: router.query.get("manufacturer"),
  };

  const requestProducts = useGetProducts({
    variables: variablesRequestProducts,
  });

  const products = useMemo(
    () =>
      requestProducts.data?.data?.filter((product, index) => {
        const attr = product?.characteristics.find(
          (item) => item.attribute.id === arttibuteFilter
        );
        return !arttibuteFilter || !attr;
      }) || [],
    [requestProducts.data, arttibuteFilter]
  );

  useEffect(() => {
    if (requestType.data) {
      pageTitle.set(routesMap.productType.getTitle(requestType.data));
    }
  }, [requestType.data]);

  const isDeleteDisabled = Boolean(
    !requestType.data ||
      requestType.data?.attributes?.length ||
      !requestProducts.data ||
      requestProducts.data?.data?.length
  );

  const handeOnSave = () => {
    requestType.refetch();
    requestProducts.refetch();
  };

  const push = (query: any) =>
    router.push(
      `${routesMap.productType.getRoute(
        requestType.data?.id
      )}?${new URLSearchParams(
        //@ts-ignore
        removeEmptyProps(query)
      )}`
    );

  const handleChangePage = (page: number) =>
    handleChangeQuery({
      offset: page * variablesRequestProducts.limit,
    });

  const handleChangeQuery = (query: any) =>
    push({
      ...variablesRequestProducts,
      ...query,
    });

  const handleSetArttibuteFilter = (attribute: ProductTypeAttribute) => {
    requestProducts.refetch();
    arttibuteFilter === attribute.id
      ? setArttibuteFilter(null)
      : setArttibuteFilter(attribute.id);
  };

  return (
    <>
      {requestType.data && (
        <ProductTypeData
          productType={requestType.data}
          isDeleteDisabled={isDeleteDisabled}
          onSave={handeOnSave}
        />
      )}
      <Box sx={{ mt: 4 }}>
        <ProductsFilters
          manufacturerId={parseInt(
            variablesRequestProducts.manufacturer as string,
            10
          )}
          brandId={parseInt(variablesRequestProducts.brand as string, 10)}
          onChange={handleChangeQuery}
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Products
          withStock
          filters={
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Box sx={{ width: "56px" }} />
              <Grid container>
                <Grid item xs={12} md={5}></Grid>
                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-end",
                    }}
                  >
                    {requestType.data?.attributes
                      ?.sort((a, b) => (a.name > b.name ? 1 : -1))
                      ?.map((attribute) => (
                        <Box
                          key={attribute.id}
                          sx={{
                            ml: 1,
                            width: characteristcEditInputWidth[attribute.type],
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <FormControlLabel
                            sx={{
                              fontSize: "10px",
                            }}
                            labelPlacement="top"
                            onChange={() => handleSetArttibuteFilter(attribute)}
                            title={`Фильтр по незаполненым "${attribute.name}"`}
                            control={
                              <Switch
                                checked={arttibuteFilter === attribute.id}
                              />
                            }
                            label={attribute.name}
                          />
                        </Box>
                      ))}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          }
          productType={requestType.data}
          products={products.sort((a, b) => (a.title > b.title ? 1 : -1))}
          limit={variablesRequestProducts.limit}
          offset={variablesRequestProducts.offset}
          count={requestProducts.data?.total_count || 0}
          onSave={requestProducts.refetch}
          loading={requestProducts.isLoading}
          onChangePage={handleChangePage}
        />
      </Box>
      <Box sx={{ mt: 4 }}>
        <PossibleProducts
          productType={requestType.data}
          onSave={requestProducts.refetch}
        />
      </Box>
    </>
  );
};
