import React, { useEffect, useState } from "react";
import {
  apiService,
  useGetManufacturer,
  useGetProducts,
} from "src/services/apiService";
import {
  AliasLabel,
  BrandLabel,
  CheckEnLabel,
  Products,
} from "src/components/common";
import {
  Box,
  Button,
  Grid,
  Icon,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Check } from "@mui/icons-material";
import { routesMap } from "src/routes";

//shared
import { usePageTitle, useRouter } from "src/shared/hooks";

interface Props {}

export const ManufacturerPage: React.FunctionComponent<Props> = ({}) => {
  const router = useRouter();
  const pageTitle = usePageTitle();

  const [name, setName] = useState<null | string>(null);

  const requestManufacturer = useGetManufacturer({
    variables: { id: router.params.manufacturerId as string },
  });

  useEffect(() => {
    if (requestManufacturer.data) {
      pageTitle.set(routesMap.manufacturer.getTitle(requestManufacturer.data));

      setName(requestManufacturer.data.name);
    }
  }, [requestManufacturer.data]);

  const requestProducts = useGetProducts({
    variables: { manufacturer: router.params.manufacturerId },
  });

  const products = requestProducts.data?.data || [];

  const handleSave = () => {
    const manufacturer: any = {};

    if (name?.trim() !== requestManufacturer.data?.name) {
      manufacturer.name = name?.trim();
    }
    if (requestManufacturer.data) {
      apiService
        .changeManufacturer({
          manufacturerId: requestManufacturer.data?.id,
          manufacturer,
        })
        .then(() => {
          requestManufacturer.refetch();
          requestProducts.refetch();
        });
    }
  };

  const isChanged = !requestManufacturer.data
    ? false
    : requestManufacturer.data?.name !== name;

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <TextField
            value={name}
            onChange={(e) => setName(e.target.value || null)}
            label="Название"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CheckEnLabel>{name}</CheckEnLabel>
                  {!isChanged && (
                    <Icon color="primary" title="Сохранено">
                      <Check />
                    </Icon>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              color="secondary"
              onClick={handleSave}
              variant="contained"
              disabled={!isChanged}
            >
              Сохранить
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">Алиасы</Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            {requestManufacturer.data?.aliases?.map((alias) => (
              <Box sx={{ mr: 1, mt: 1 }} key={alias.id}>
                <AliasLabel
                  alias={alias}
                  onDelete={requestManufacturer.refetch}
                />
              </Box>
            ))}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">Бренды</Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            {requestManufacturer.data?.brands?.map((brand) => (
              <Box sx={{ mr: 1, mt: 1 }} key={brand.id}>
                <BrandLabel
                  brand={brand}
                  linkIcon
                  onDelete={() =>
                    // @ts-ignore
                    window
                      ?.open(routesMap.brand.getRoute(brand.id), "_blank")
                      ?.focus()
                  }
                />
              </Box>
            ))}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Products
            products={products}
            limit={products.length}
            offset={0}
            loading={requestProducts.isLoading}
            onSave={requestProducts.refetch}
          />
        </Grid>
      </Grid>
    </div>
  );
};
