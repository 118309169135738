import { TextField, Typography } from "@mui/material";
import React from "react";

//shared
import { MetaTagData as IMetaTagData } from "src/shared/api";

interface Props {
  data?: IMetaTagData;
  onChange: (key: string, value: string) => any;
}

const fields = ["title", "description", "keywords"];

export const MetaTagData: React.FunctionComponent<Props> = ({
  data,
  onChange,
}) => {
  return (
    <>
      <Typography variant="h5">Мета данные</Typography>
      {fields.map((field, index) => (
        <TextField
          sx={{ mt: 2 }}
          fullWidth
          key={field}
          label={field}
          //@ts-ignore
          value={data?.[field] || ""}
          onChange={(e) => onChange(field, e.target.value)}
        />
      ))}
    </>
  );
};
