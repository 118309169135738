import React, { useState } from "react";
import { Add } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { apiService } from "src/services/apiService";
import { CheckEnLabel } from "src/components/common";

//shared
import { ProductTypeAttributeType } from "src/shared/api";

interface Props {
  onResponse: () => any;
}

const hints = {
  [ProductTypeAttributeType.bool]: "да / нет",
  [ProductTypeAttributeType.string]: "аромат, тип стирки, эффект и т.д",
  [ProductTypeAttributeType.numeric]: "объем, вес, количество и т.д",
};

export const AttributeCreateDialog: React.FunctionComponent<Props> = ({
  onResponse,
}) => {
  const [open, setOpen] = useState(false);

  const [name, setName] = useState("");

  const [type, setType] = useState<ProductTypeAttributeType>(
    ProductTypeAttributeType.string
  );

  const handleOpen = () => {
    setOpen(true);
    setName("");
  };

  const handleClose = () => {
    setOpen(false);
    setName("");
  };

  const handleChangeName = (e: any) => setName(e.currentTarget.value);

  const handleConfirm = () =>
    apiService.createAttribute({ name: name.trim(), type }).then(() => {
      onResponse();
      handleClose();
    });

  return (
    <>
      <Fab
        size="small"
        onClick={handleOpen}
        color="secondary"
        title="Создать атрибут"
      >
        <Add />
      </Fab>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Создание атрибута</DialogTitle>
        <DialogContent>
          <TextField
            sx={{ mt: 1 }}
            fullWidth
            value={name}
            onChange={handleChangeName}
            label="Название"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CheckEnLabel>{name}</CheckEnLabel>
                </InputAdornment>
              ),
            }}
          />
          <Select
            sx={{ mt: 2 }}
            fullWidth
            variant="outlined"
            value={type}
            onChange={(e) =>
              setType(e.target.value as ProductTypeAttributeType)
            }
          >
            <MenuItem value={ProductTypeAttributeType.string}>
              {ProductTypeAttributeType.string}
            </MenuItem>
            <MenuItem value={ProductTypeAttributeType.numeric}>
              {ProductTypeAttributeType.numeric}
            </MenuItem>
            <MenuItem value={ProductTypeAttributeType.bool}>
              {ProductTypeAttributeType.bool}
            </MenuItem>
          </Select>
          <Box sx={{ mt: 2 }}>{hints[type]}</Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Закрыть</Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={!name}
            onClick={handleConfirm}
          >
            Создать
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
