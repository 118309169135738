import { Chip } from "@mui/material";
import React from "react";
import { apiService } from "src/services/apiService";

//shared
import { Alias } from "src/shared/api";

interface Props {
  alias: Alias;
  onDelete?: () => any;
}

export const AliasLabel: React.FunctionComponent<Props> = ({
  alias,
  onDelete,
}) => {
  const handleDelete = () =>
    apiService.deleteAlias(alias.id).then(() => {
      if (onDelete) onDelete();
    });

  return (
    <Chip
      title="Алиас"
      color="default"
      size="small"
      label={alias?.value}
      onDelete={onDelete ? handleDelete : undefined}
    />
  );
};
