import { Box, Fade, Skeleton } from "@mui/material";
import React from "react";
import { FCWithChildren } from "src/types/app";

interface Props {
  loading: boolean;
  width?: number | string;
  height?: number | string;
  rows?: number;
  sx?: any;
  variant?: "text" | "circular" | "rectangular";
}

export const WithLoading: FCWithChildren<Props> = ({
  loading,
  sx = {},
  children,
  width = "100%",
  height,
  rows = 1,
  variant = "rectangular",
}) =>
  //@ts-ignore
  !loading && children
    ? children
    : Array(rows)
        .fill(
          <Skeleton
            variant={variant}
            component="span"
            sx={sx}
            width={width}
            animation="wave"
            height={height}
          />
        )
        .map((item, i) => (
          <Box
            component="span"
            key={i}
            sx={{
              display: 'inline-block',
              mt: i && 0.05,
              width,
            }}
          >
            {item}
          </Box>
        ));
