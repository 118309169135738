import { LinkOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { routesMap } from "src/routes";
import { useGetBrands } from "src/services/apiService";

//shared
import { Brand } from "src/shared/api";

interface Props {
  disabled?: boolean;
  label?: string;
  brandId?: number | null;
  manufacturerId?: number | null;
  onSelect: (value: Brand | null) => any;
}

export const BrandSelect: React.FunctionComponent<Props> = ({
  disabled,
  brandId,
  manufacturerId,
  label = "Бренд",
  onSelect,
}) => {
  const request = useGetBrands({
    variables: {
      manufacturer: manufacturerId,
    },
  });

  const [value, setValue] = useState<null | Brand>(null);

  const types = useMemo(
    () => request.data?.sort((a, b) => (a.name > b.name ? 1 : -1)),
    [request.data]
  );

  useEffect(() => {
    if (brandId && types) {
      const valueFromList = types.find((item) => item.id === brandId);
      if (valueFromList) {
        setValue(valueFromList);
      }
    }
  }, [brandId, types]);

  const handleChange = (e: any, value: null | Brand) => {
    setValue(value);
    onSelect(value);
  };

  return (
    <Autocomplete
      disabled={disabled}
      value={value}
      onChange={handleChange}
      getOptionLabel={(item) => item.name || "Бренд без названия"}
      options={types || []}
      loading={request.isLoading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                <InputAdornment position="end">
                  {value && (
                    <IconButton
                      href={routesMap.brand.getRoute(value.id)}
                      target="_blank"
                    >
                      <LinkOutlined />
                    </IconButton>
                  )}
                </InputAdornment>
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
