import React, { useEffect } from "react";
import {
  removeEmptyProps,
  requsetGetInstockMissingCategoriesProducts,
  useGetProducts,
} from "src/services/apiService";
import { Products, ProductsFilters } from "src/components/common";
import { Box, Button, Grid } from "@mui/material";
import { routes, routesMap } from "src/routes";

//shared
import { useRouter, useSelectItems } from "src/shared/hooks";
import { Product } from "src/shared/api";

interface Props {}

const initQuery = {
  limit: 50,
  offset: 0,
  // binding: "OR",
};

export const ProductsPage: React.FunctionComponent<Props> = ({}) => {
  const router = useRouter();

  const selectItems = useSelectItems<Product>();

  useEffect(() => {
    selectItems.setItems([]);
  }, [router.query]);

  const variables = {
    ...initQuery,
    limit: parseInt(router.query.get("limit") as string, 10) || initQuery.limit,
    offset:
      parseInt(router.query.get("offset") as string, 10) || initQuery.offset,
    brand: router.query.get("brand"),
    manufacturer: router.query.get("manufacturer"),
    barcode: router.query.get("barcode"),
    ids: router.query.get("ids"),
  };

  const request = useGetProducts({ variables });

  const handleGetInstockMissingCategoriesProductsIds = () => {
    requsetGetInstockMissingCategoriesProducts().then((res) => {
      location.href = `${routesMap.products.getRoute()}?ids=${res?.data
        ?.filter((item: any) => item?.bcode)
        ?.map((item: any) => item?.bcode)
        ?.join(",")}`;
    });
  };

  const push = (query: any) =>
    router.push(
      `${routesMap.products.getRoute()}?${new URLSearchParams(
        //@ts-ignore
        removeEmptyProps(query)
      )}`
    );

  const handleChangePage = (page: number) =>
    push({
      ...variables,
      offset: page * variables.limit,
    });

  const onChangeFilter = (nextQuery: any) =>
    push({
      ...initQuery,
      ...nextQuery,
    });

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ProductsFilters
            manufacturerId={parseInt(variables.manufacturer as string, 10)}
            brandId={parseInt(variables.brand as string, 10)}
            barcode={variables.barcode}
            onChange={onChangeFilter}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={handleGetInstockMissingCategoriesProductsIds}
            variant="text"
          >
            Продукты instock-dv.ru без типов
          </Button>
        </Grid>
      </Grid>
      <Box mt={2}>
        <Products
          withStock
          onSave={request.refetch}
          products={request.data?.data}
          onChangePage={handleChangePage}
          limit={variables.limit}
          offset={variables.offset}
          loading={request.isLoading}
          count={request.data?.total_count}
        />
      </Box>
    </div>
  );
};
