import React, { useEffect, useMemo, useState } from "react";
//@ts-ignore
import nearestColor from "nearest-color";
import {
  Button,
  ButtonBase,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { read, Sheet as ISheet, utils, WorkBook } from "xlsx";
import { useDropzone } from "react-dropzone";
import { UploadFileOutlined, VisibilityOffOutlined } from "@mui/icons-material";
import { grey, red } from "@mui/material/colors";
import Color from "color";
import colors from "src/colorsList.json";
import {
  BrandLabel,
  BrandSelect,
  BrandSelectDialog,
  ManufacturerLabel,
  ManufacturerSelect,
  ManufacturerSelectDialog,
  ProdcutSeriesSelectDialog,
  ProductTypeLabel,
  ProductTypeSelect,
  ProductTypeSelectDialog,
  SeriesLabel,
  WithConfirm,
  WithLoading,
} from "src/components/common";
import { useGetProducts } from "src/services/apiService";

//entities
import { ProductPreview } from "src/entities/product";

//shared
import { useDB, useSelectItems } from "src/shared/hooks";
import {
  Brand,
  Manufacturer,
  Product,
  ProductType,
  Series,
} from "src/shared/api";

const NewProductEdit = ({
  item,
  onChange,
  propertiesMap,
}: {
  item: any;
  propertiesMap: any;
  onChange: (value: any) => any;
}) => {
  const handleManufacturer = (manufacturer: Manufacturer) =>
    onChange({
      ...item,
      product: { ...item.product, manufacturer, brand: null, series: null },
    });
  const handleBrand = (brand: Brand) =>
    onChange({
      ...item,
      product: {
        ...item.product,
        brand,
        manufacturer: brand?.manufacturer,
        series: null,
      },
    });
  const handleSeries = (series: Series) =>
    onChange({ ...item, product: { ...item.product, series } });
  const handleType = (type: ProductType | null) =>
    onChange({ ...item, product: { ...item.product, type } });

  return (
    <Box>
      {typeof propertiesMap?.name === "number" ? (
        <Box>{item?.data[propertiesMap?.name]}</Box>
      ) : (
        <Box>
          <Typography color="error" variant="body2">
            Не выбран столбец названия
          </Typography>
        </Box>
      )}
      <Box sx={{ display: "flex", mt: 1 }}>
        <ManufacturerSelectDialog
          onSave={handleManufacturer}
          manufacturerId={item?.product?.manufacturer?.id}
        >
          <ManufacturerLabel
            manufacturer={item?.product?.manufacturer}
            linkIcon={Boolean(item?.product?.manufacturer)}
          />
        </ManufacturerSelectDialog>
        <Box sx={{ ml: 1 }}>
          <BrandSelectDialog
            onSave={handleBrand}
            brandId={item?.product?.brand?.id}
          >
            <BrandLabel
              brand={item?.product?.brand}
              linkIcon={Boolean(item?.product?.brand)}
            />
          </BrandSelectDialog>
        </Box>
        <Box sx={{ ml: 1 }}>
          <ProductTypeSelectDialog
            onSave={handleType}
            productTypeId={item?.product?.type?.id}
          >
            <ProductTypeLabel
              type={item?.product?.type}
              linkIcon={Boolean(item?.product?.type)}
            />
          </ProductTypeSelectDialog>
        </Box>
        {item?.product?.brand && (
          <Box sx={{ ml: 1 }}>
            <ProdcutSeriesSelectDialog
              brand={item?.product?.brand}
              onSave={handleSeries}
              seriesId={item?.product?.series?.id}
            >
              <SeriesLabel
                series={item?.product?.series}
                linkIcon={Boolean(item?.product?.series)}
              />
            </ProdcutSeriesSelectDialog>
          </Box>
        )}
      </Box>
    </Box>
  );
};
interface Props {}

const colorsMap = colors.reduce(
  (prev, curr) => ({ ...prev, [curr.name]: curr.value }),
  {}
);

const getNearestColor = nearestColor.from(colorsMap);

enum ProductProperty {
  barcode = "barcode",
  name = "name",
  weight = "weight",
  length = "length",
  width = "width",
  height = "height",
}

const propertiesNamesMap = {
  [ProductProperty.barcode]: "Штрихкод",
  [ProductProperty.name]: "Название",
  [ProductProperty.weight]: "Вес",
  [ProductProperty.length]: "Длина",
  [ProductProperty.width]: "Ширина(глубина)",
  [ProductProperty.height]: "Высота",
};

const PropertySelect = ({
  index,
  onSelect,
  propertiesMap,
}: {
  index: number;
  onSelect: (value: Partial<PropertiesMap>) => any;
  propertiesMap: PropertiesMap;
}) => {
  const property =
    Object.entries(propertiesMap).find((item) => item[1] === index)?.[0] ||
    null;
  const handleChange = (e: any) =>
    onSelect({
      [e.target.value]: e.target.value ? index : null,
      //@ts-ignore
      [property]: null,
    });
  return (
    <FormControl fullWidth size="small">
      <Select
        variant="outlined"
        //@ts-ignore
        value={propertiesMap?.[property] === index ? property : ""}
        onChange={handleChange}
      >
        <MenuItem
          //@ts-ignore
          value=""
        >
          Не выбрано
        </MenuItem>
        {Object.values(ProductProperty)?.map((item) => (
          <MenuItem value={item} key={item}>
            {propertiesNamesMap[item]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const Sheet = ({
  sheet,
  onSave,
  sheetName,
}: {
  sheet: any;
  onSave: () => any;
  sheetName?: string | null;
}) => {
  const db = useDB();
  const [headerRowIndex, setHeaderRowIndex] = useState<number>(0);

  const [propertiesMap, setPropertiesMap] = useState<PropertiesMap>({});

  const [sheetSettings, setSheetSettings] = useState<any>({});

  const [hidExisted, setHidExisted] = useState(false);

  const selectItems = useSelectItems();

  const handleRefetch = async () => {
    const id = `sheetSettings_${sheetName}`;
    await db.get("settings", id).then((data) => {
      if (data) {
        setHeaderRowIndex(data?.headerRowIndex);
        setPropertiesMap(data?.propertiesMap);
        setSheetSettings(data);
      }
    });
  };

  useEffect(() => {
    if (sheetName) {
      handleRefetch();
    }
  }, [sheetName]);

  const handleSetPropertiesMap = (value: Partial<PropertiesMap>) => {
    db.add({
      ...sheetSettings,
      propertiesMap: { ...sheetSettings.propertiesMap, ...value },
    }).then(handleRefetch);
  };

  const handleSetHeaderRowIndex = (e: any) => {
    selectItems.setItems([]);
    db.add({
      ...sheetSettings,
      headerRowIndex:
        parseInt(e.target.value, 10) < 0 ? 0 : parseInt(e.target.value, 10),
    }).then(handleRefetch);
  };

  const handleHidColumn = (index?: number) => {
    db.add({
      ...sheetSettings,
      hiddenColumns:
        typeof index === "number"
          ? [...(sheetSettings?.hiddenColumns || []), index]
          : [],
    }).then(handleRefetch);
  };

  const handleChangeItem = async (item: any) => {
    await db.add(item);
    onSave();
  };
  const table = (sheet || []) as Array<{
    index: string;
    data: string[];
    product: any;
  }>;

  const barcodes = useMemo(
    () =>
      typeof propertiesMap?.barcode === "number"
        ? table
            ?.map((row) => row?.data[propertiesMap.barcode as number])
            ?.join(",")
        : "",
    [table, propertiesMap?.barcode]
  );

  const existedProducts = useGetProducts({
    variables: { barcodes },
    skip: !barcodes,
  });

  const existedProductsMap =
    useMemo(
      () =>
        existedProducts.data?.data?.reduce(
          (prev, curr) => ({ ...prev, [curr.barcode?.value as string]: curr }),
          {}
        ),
      [existedProducts.data]
    ) || {};

  const mostColumnsCount = table?.[headerRowIndex]?.data?.length | 0;

  const handleChangeSelected = (data: {
    manufacturer?: Manufacturer | null;
    brand?: Brand | null;
    series?: Series | null;
    type?: ProductType | null;
  }) => {
    Promise.all(
      selectItems.valuesList?.map((item: any) => {
        const row = table.find((row) => row.index === item.id) || {
          product: {},
        };
        return db.add({ ...row, product: { ...row.product, ...data } });
      })
    ).then(onSave);
  };

  const isRequiredPropsSet =
    typeof propertiesMap?.barcode === "number" &&
    typeof propertiesMap?.name === "number";

  const valideItems = useMemo(
    () =>
      isRequiredPropsSet
        ? table
            ?.slice(headerRowIndex, table.length)
            ?.filter((row) =>
              validateRow(
                row?.data,
                propertiesMap,
                mostColumnsCount,
                existedProductsMap
              )
            )
        : [],
    [table, isRequiredPropsSet]
  );

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            label="Строка заголовков столбцов"
            value={headerRowIndex}
            type="number"
            onChange={handleSetHeaderRowIndex}
          />
          <Button
            color="secondary"
            disabled={!sheetSettings?.hiddenColumns?.length}
            sx={{ ml: 2 }}
            size="small"
            variant="text"
            onClick={() => handleHidColumn()}
          >
            Показать скрытые столбцы (
            {sheetSettings?.hiddenColumns?.length || 0})
          </Button>

          <Button
            disabled={!existedProducts.data?.total_count}
            color={hidExisted ? "secondary" : "primary"}
            sx={{ ml: 2 }}
            size="small"
            variant="text"
            onClick={() => setHidExisted(!hidExisted)}
          >
            {hidExisted
              ? `Показать скрытые продукты (${
                  existedProducts.data?.total_count || 0
                })`
              : `Скрыть существующие продукты (${
                  existedProducts.data?.total_count || 0
                })`}
          </Button>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <ManufacturerSelect
              label="Назначить производителя"
              disabled={!selectItems.items.size}
              onSelect={(manufacturer) =>
                handleChangeSelected({ manufacturer })
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <BrandSelect
              label="Назначить бренд"
              disabled={!selectItems.items.size}
              onSelect={(brand) =>
                handleChangeSelected({
                  brand,
                  manufacturer: brand?.manufacturer,
                })
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ProductTypeSelect
              label="Назначить тип продукта"
              disabled={!selectItems.items.size}
              onSelect={(type) => handleChangeSelected({ type })}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ color: grey[500], padding: "2px 3px" }}>
                  #
                </TableCell>
                <TableCell
                  padding="checkbox"
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    if (valideItems.length) {
                      selectItems.items.size
                        ? selectItems.setItems([])
                        : selectItems.setItems(
                            valideItems.map((row) => ({
                              id: row.index,
                            }))
                          );
                    }
                  }}
                >
                  <Checkbox
                    size="small"
                    disabled={!valideItems.length}
                    checked={Boolean(
                      selectItems.items.size &&
                        selectItems.items.size === valideItems.length
                    )}
                  />
                </TableCell>
                <TableCell />
                {Array(table?.[headerRowIndex]?.data?.length || 0)
                  .fill(null)
                  ?.map((cell, i: number) => {
                    if (sheetSettings?.hiddenColumns?.includes(i)) return null;
                    return (
                      <TableCell key={i}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <PropertySelect
                            index={i}
                            onSelect={handleSetPropertiesMap}
                            propertiesMap={propertiesMap}
                          />
                          <IconButton
                            size="small"
                            title="Скрыть"
                            onClick={() => handleHidColumn(i)}
                          >
                            <VisibilityOffOutlined />
                          </IconButton>
                        </Box>
                      </TableCell>
                    );
                  })}
              </TableRow>
              {Boolean(headerRowIndex) && (
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "2px 3px",
                    }}
                  />
                  <TableCell
                    sx={{
                      padding: "2px 3px",
                    }}
                  />
                  <TableCell
                    sx={{
                      padding: "2px 3px",
                    }}
                  >
                    Продукт
                  </TableCell>
                  {table?.[headerRowIndex - 1]?.data?.map((cell, i) => {
                    if (sheetSettings?.hiddenColumns?.includes(i)) return null;
                    return (
                      <TableCell
                        key={i}
                        sx={{
                          padding: "2px 3px",
                        }}
                      >
                        {" "}
                        {cell}
                      </TableCell>
                    );
                  })}
                </TableRow>
              )}
            </TableHead>
            <TableBody>
              {table?.map((row: any, index) => {
                if (index < headerRowIndex) return null;

                if (
                  hidExisted &&
                  validateProductAleadyExist(
                    row.data,
                    propertiesMap,
                    existedProductsMap
                  )
                ) {
                  return null;
                }

                return (
                  <TableRow
                    hover
                    key={index}
                    sx={{
                      backgroundColor: !validateRowCount(
                        row?.data,
                        mostColumnsCount
                      )
                        ? Color(red[500]).alpha(0.2).toString()
                        : "unset",
                    }}
                  >
                    <TableCell sx={{ color: grey[500] }}>{index + 1}</TableCell>
                    <TableCell
                      padding="checkbox"
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        if (
                          isRequiredPropsSet &&
                          validateRow(
                            row?.data,
                            propertiesMap,
                            mostColumnsCount,
                            existedProductsMap
                          )
                        ) {
                          selectItems.setItem({
                            id: row.index,
                          });
                        }
                      }}
                    >
                      <Checkbox
                        sx={{ height: "100%" }}
                        size="small"
                        disabled={
                          !isRequiredPropsSet ||
                          !validateRow(
                            row?.data,
                            propertiesMap,
                            mostColumnsCount,
                            existedProductsMap
                          )
                        }
                        checked={selectItems.items.has(row.index)}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "4px 3px",
                      }}
                    >
                      {typeof propertiesMap?.barcode !== "number" && (
                        <Typography color="error" variant="body2">
                          Не выбран столбец со штрихкодом
                        </Typography>
                      )}
                      {typeof propertiesMap?.barcode === "number" &&
                        !validateProductAleadyExist(
                          row.data,
                          propertiesMap,
                          existedProductsMap
                        ) && (
                          <NewProductEdit
                            onChange={handleChangeItem}
                            item={row}
                            propertiesMap={propertiesMap}
                          />
                        )}
                      {validateProductAleadyExist(
                        row.data,
                        propertiesMap,
                        existedProductsMap
                      ) && (
                        <ProductPreview
                          product={
                            //@ts-ignore
                            existedProductsMap[
                              row?.data[
                                propertiesMap?.barcode as number
                              ] as string
                            ]
                          }
                        />
                      )}
                    </TableCell>
                    {Array.from(row?.data)
                      ?.map((item) => item || "")
                      ?.map((cell: any, i) => {
                        if (sheetSettings?.hiddenColumns?.includes(i))
                          return null;
                        return (
                          <TableCell
                            key={i}
                            sx={{
                              padding: "2px 3px",
                              fontSize: "12px",
                              backgroundColor: validateCell(
                                i,
                                propertiesMap,
                                cell
                              )
                                ? "unset"
                                : Color(red[500]).alpha(0.2).toString(),
                            }}
                          >
                            {cell}
                          </TableCell>
                        );
                      })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            color="secondary"
            disabled={!selectItems.items.size}
          >
            Создать
            {Boolean(selectItems.items.size) && ` (${selectItems.items.size})`}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

const validateBarcode = (value: string | number | null) =>
  value ? String(value)?.trim()?.length === 13 : false;

const validateName = (value: string | number | null) =>
  value ? Boolean(String(value)?.trim()?.length) : false;

const validateNumeric = (value: string | number | null) => {
  if (value === null) return false;

  if (typeof value === "number") return true;

  if (isNaN(parseFloat(value))) return false;

  return false;
};

const validatorsMap = {
  name: validateName,
  barcode: validateBarcode,
  weight: validateNumeric,
  length: validateNumeric,
  width: validateNumeric,
  height: validateNumeric,
};

const validateCell = (
  index: number,
  propertiesMap: any,
  value: string | number
) => {
  const property = Object.entries(propertiesMap)?.find(
    (item) => item[1] === index
  )?.[0] as string;
  //@ts-ignore
  if (property) return validatorsMap[property]?.(value);
  return true;
};

const validateRowCount = (row: string[], count: number) => count === row.length;

const validateProductAleadyExist = (
  row: string[],
  propertiesMap: any,
  existedProductsMap: { [key: number]: Product } | null
) => {
  return Boolean(
    typeof propertiesMap?.barcode === "number" &&
      row &&
      row[propertiesMap?.barcode] &&
      existedProductsMap &&
      //@ts-ignore
      existedProductsMap[row[propertiesMap?.barcode]]
  );
};
const validateRow = (
  row: string[],
  propertiesMap: any,
  count: number,
  existedProductsMap: { [key: number]: Product } | null
) =>
  validateRowCount(row, count) &&
  row?.every((value, index) => validateCell(index, propertiesMap, value)) &&
  !validateProductAleadyExist(row, propertiesMap, existedProductsMap);

interface PropertiesMap {
  [key: number | string]: ProductProperty | number | null;
}

const ProductsParser = () => {
  const [fileLoading, setFileLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [workbook, setWorkbook] = useState<any>(null);

  const [sheet, setSheet] = useState<any>(null);

  const db = useDB();

  const handleUpload = async (filesList: any) => {
    setFileLoading(true);
    await db.dropDb();
    if (filesList && filesList.length) {
      const data = await filesList[0]?.arrayBuffer();

      const workbook = await read(data);

      workbook.SheetNames?.forEach((sheetName) =>
        (
          (utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 }) ||
            []) as Array<string[]>
        ).forEach((data: string[], index: number) => {
          if (data?.some((value) => value)) {
            db.add({
              sheetName,
              index: `${sheetName}_${index}`,
              data,
              table: "priceElements",
              product: {},
            });
          }
        })
      );
      const { SheetNames } = workbook;

      await db.add({
        key: "priceFile",
        SheetNames,
        activeSheet: workbook.SheetNames[0],
        path: filesList[0]?.path,
        table: "settings",
      });

      workbook.SheetNames?.forEach(
        async (name) =>
          await db.add({
            key: `sheetSettings_${name}`,
            table: "settings",
            propertiesMap: {},
            headerRowIndex: 0,
            hiddenColumns: [],
          })
      );
      handleRefetch();
    }
  };

  const handleRefetch = async () => {
    setFileLoading(false);
    await db.get("settings", "priceFile").then((data) => setWorkbook(data));

    await db
      .getAll("priceElements")
      ?.then((rows) => {
        return rows?.filter((row) => row?.sheetName === workbook?.activeSheet);
      })
      .then((data) => setSheet(data));
  };

  const handleSetSheetName = async (e: any, value: string) =>
    db.add({ ...workbook, activeSheet: value }).then(handleRefetch);

  const handleClear = () => {
    setFileLoading(false);
    db.dropDb().then(handleRefetch);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleUpload,
  });

  useEffect(() => {
    handleRefetch();
  }, [workbook?.activeSheet]);

  return (
    <>
      <WithLoading loading={fileLoading} height={78}>
        <>
          {!workbook && (
            <Box>
              <Paper sx={{ display: "inline-block" }}>
                <ButtonBase
                  {...getRootProps({})}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    p: 2,
                  }}
                >
                  <>
                    <Box sx={{ fontSize: "12px" }}>
                      перетащите файл или нажмите для выбора
                    </Box>
                    <input {...getInputProps()} />

                    <UploadFileOutlined sx={{ mt: 1 }} />
                  </>
                </ButtonBase>
              </Paper>
            </Box>
          )}
        </>
      </WithLoading>
      {workbook && (
        <>
          <Box sx={{ mt: 2, display: "flex", alignItems: "center" }}>
            {workbook?.path}{" "}
            <Box sx={{ ml: 2 }}>
              <WithConfirm confirmTitle="Очистрить" onConfirm={handleClear}>
                <Button variant="contained" color="secondary">
                  Очистить
                </Button>
              </WithConfirm>
            </Box>
          </Box>
          <Box sx={{ mt: 4 }}>
            <Tabs onChange={handleSetSheetName} value={workbook?.activeSheet}>
              <Tab label="Страницы файла" disabled />
              {workbook?.SheetNames?.map((name: string) => (
                <Tab key={name} value={name} label={name} />
              ))}
            </Tabs>
          </Box>
          <Box sx={{ mt: 4 }}>
            {sheet && (
              <Sheet
                sheet={sheet}
                onSave={handleRefetch}
                sheetName={workbook?.activeSheet}
              />
            )}
          </Box>
        </>
      )}
    </>
  );
};

export const Playground: React.FunctionComponent<Props> = ({}) => {
  const [state, setState] = useState("#009688");

  let color: any = null;

  try {
    color = getNearestColor(state);
  } catch {}

  return (
    <div>
      <Typography variant="h4">Поиск ближайшего цвета</Typography>
      <div>
        <TextField value={state} onChange={(e) => setState(e.target.value)} />
      </div>
      <Box mt={2}>
        <Box
          sx={{
            width: "100px",
            height: "50px",
            backgroundColor: `#${color?.value}`,
          }}
        ></Box>
      </Box>
      <Box mt={2}>{color && JSON.stringify(color)}</Box>
      <Box mt={2}>
        <Typography variant="h4">Парсер прайс-листа</Typography>
        <ProductsParser />
      </Box>
    </div>
  );
};
