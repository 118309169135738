import { LinkOutlined } from "@mui/icons-material";
import { Chip } from "@mui/material";
import React from "react";
import { routesMap } from "src/routes";

//shared
import { Brand } from "src/shared/api";

interface Props {
  brand?: Brand | null;
  clickable?: boolean;
  linkIcon?: boolean;
  tabIndex?: number;
  onDelete?: () => any;
  deleteIcon?: () => any;
  onClick?: () => any;
}

export const BrandLabel: React.FunctionComponent<Props> = ({
  brand,
  tabIndex = 0,
  onDelete,
  onClick,
  linkIcon,
}) => {
  return (
    <Chip
      title="Бренд"
      sx={{ opacity: brand ? 1 : 0.8 }}
      color="default"
      variant={brand ? "filled" : "outlined"}
      icon={<routesMap.brands.icon />}
      size="small"
      clickable={onClick ? true : undefined}
      tabIndex={tabIndex}
      label={brand ? brand?.name || "Бренд без названия" : null}
      onClick={onClick}
      onDelete={onDelete}
      deleteIcon={linkIcon ? <LinkOutlined /> : undefined}
    />
  );
};
