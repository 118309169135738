import { useEffect, useState } from "react";
import { openDB, deleteDB, wrap, unwrap, IDBPDatabase } from "idb";

export const useDB = () => {
  const name = "priceParcer";

  const getDb = async () => {
    const db = await openDB<{}>(name, 1, {
      upgrade(db) {
        const priceFile = db.createObjectStore("settings", {
          keyPath: "key",
        });

        //@ts-ignore
        priceFile.createIndex("settingsIndex", "key");

        const priceElements = db.createObjectStore("priceElements", {
          keyPath: "index",
        });

        //@ts-ignore
        priceElements.createIndex("priceElementsIndex", "index");
      },
      blocked() {
        console.log("db blocked");
      },
    });

    return db;
  };

  useEffect(() => {
    getDb();
  }, []);

  const add = async (data: any) => {
    const db = await getDb();
    // Start a new transaction
    const transaction = db?.transaction(data.table, "readwrite");
    const objectStore = transaction?.objectStore(data.table);
    // Add some data
    const request = await objectStore?.put(data);

    // if (request) {
    //   request.onsuccess = function () {
    //     // event.target.result === customer.ssn;
    //     //@ts-ignore
    //     // console.log("request.onsuccess: " + event?.target?.result);
    //   };

    //   request.onerror = function () {
    //     // event.target.result === customer.ssn;
    //     //@ts-ignore
    //     console.log("request.onerror: " + request?.errorCode);
    //   };
    // }

    if (transaction) {
      // transaction.oncomplete = function (event) {
      //   console.log("All added to " + objectStore + "!");
      // };
      // transaction.onerror = function (event) {
      //   // Don't forget to handle errors!
      //   console.log("Error in adding data to " + objectStore + "!");
      // };
    }
  };

  const get = async (objectStoreName: any, id: string | number) => {
    const db = await getDb();
    // Start a new transaction
    const transaction = db?.transaction(objectStoreName);

    const objectStore = transaction?.objectStore(objectStoreName);

    const index = objectStore?.index(`${objectStoreName}Index`);

    const getDDIData = await index?.get(id);

    return getDDIData;
  };

  const getAll = async (objectStoreName: any) => {
    const db = await getDb();
    // Start a new transaction
    const transaction = db?.transaction(objectStoreName);

    const objectStore = transaction?.objectStore(objectStoreName);

    const response = await objectStore?.getAll();

    return response;
  };

  const dropDb = async () => 
    await deleteDB(name);

  return { getDb, get, getAll, add, dropDb };
};
