import { Box, ButtonBase, ClickAwayListener, Tooltip } from "@mui/material";
import React, { ReactNode } from "react";
import { FCWithChildren } from "src/types/app";

interface Props {
  text: string;
  children: ReactNode;
  tabIndex?: number;
}

export const CopyToClipboard: FCWithChildren<Props> = ({
  children,
  text,
  tabIndex = 0,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
    navigator.clipboard.writeText(text);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <span>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title="Скопировано в буфер"
        >
          <Box
            component="span"
            sx={{ cursor: "pointer" }}
            onClick={handleTooltipOpen}
            tabIndex={tabIndex}
          >
            {children}
          </Box>
        </Tooltip>
      </span>
    </ClickAwayListener>
  );
};
