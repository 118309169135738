import React from "react";
import { Star, StarBorder } from "@mui/icons-material";
import { grey, yellow } from "@mui/material/colors";
import { Box } from "@mui/material";

interface Props {
  rating?: number;
}

export const Raiting: React.FunctionComponent<Props> = ({ rating = 0 }) => {
  return (
    <Box sx={{ display: "flex" }}>
      {Array(5)
        .fill(null)
        .map((item, index) => (
          <Box
            key={index}
            sx={{
              color: grey[500],
              lineHeight: 1,
              "& > svg": {
                width: "20px",
                height: "20px",
              },
            }}
          >
            {index + 1 > rating ? (
              <StarBorder />
            ) : (
              <Star
                sx={{
                  color: yellow[700],
                }}
              />
            )}
          </Box>
        ))}
    </Box>
  );
};
