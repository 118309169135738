import React, { ReactElement } from "react";
import { Box, Checkbox, Paper } from "@mui/material";
import { grey } from "@mui/material/colors";

//entities
import { UserPreview } from "src/entities/user";
import { changeReview } from "src/entities/review";

//shared
import { formatDate } from "src/shared/libs/date";
import { Raiting } from "src/shared/ui";
import { Review as IReview } from "src/shared/api";

interface Props {
  review: IReview;
  onChange: any;
  product?: ReactElement;
}

export const Review: React.FunctionComponent<Props> = ({
  review,
  onChange,
  product,
}) => {
  const handleChange = () =>
    changeReview({ id: review.id, enabled: !review.enabled }).then(() =>
      onChange()
    );

  return (
    <Box
      sx={{
        opacity: review.enabled ? "1" : "0.3",
        paddingBottom: "16px",
        "&:hover": {
          opacity: "1",
        },
        borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
      }}
    >
      {product && <Box sx={{ marginBottom: "8px" }}>{product}</Box>}

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Raiting rating={review.rating} />
        <Box sx={{ ml: 1, color: grey[500] }}>
          {formatDate(review.created_at)}
        </Box>
        <Box sx={{ ml: 1 }}>
          <Checkbox
            checked={review.enabled}
            onChange={handleChange}
            title={review.enabled ? "Скрыть" : "Опубликовать"}
          />
        </Box>
        <Box sx={{ ml: 1 }}>
          <UserPreview user={review.user} />
        </Box>
      </Box>
      {Boolean(review.comment) && (
        <Box sx={{ display: "flex", marginTop: "4px" }}>
          <Box
            sx={(theme) => ({
              padding: "8px 16px",
              backgroundColor: theme.palette.action.selected,
              color: theme.palette.text.primary,
              borderRadius: "8px",
            })}
          >
            {review.comment}
          </Box>
        </Box>
      )}
    </Box>
  );
};
