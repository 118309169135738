import { Button, ButtonBase } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import { useGetCharacteristicSuggestions } from "src/services/apiService";

const SmallButton = styled(Button)({
  padding: "3px 3px",
  lineHeight: 1,
  minWidth: "unset",
});

interface Props {
  unit: string;
  value: number;
  onClick: (value: number) => any;
}

export const CharacteristcEditValueHelper: React.FunctionComponent<Props> = ({
  unit,
  value,
  onClick,
}) => {
  const request = useGetCharacteristicSuggestions({
    variables: { unit, value },
  });

  const handleClick = (e: any) => onClick(parseInt(e.target.value, 10));

  return (
    <>
      {request.data?.data?.map((item) => (
        <SmallButton
          key={item.value}
          color="primary"
          size="small"
          sx={{ mx: 0.2, textTransform: "lowercase" }}
          variant="outlined"
          value={item.value}
          onClick={handleClick}
        >
          {item.adapt_value}
        </SmallButton>
      ))}
    </>
  );
};
