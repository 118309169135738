import { Box } from "@mui/material";
import React from "react";
import {
  ProductProperyLabel,
  ProductProperyLabelVariant,
  WithLoading,
} from "src/components/common";

//shared
import { Product } from "src/shared/api";

interface Props {
  onSave?: () => any;
  tabIndex?: number;
  product: Product;
  withSeries?: boolean;
  loading?: boolean;
}

export const ProductProperties: React.FunctionComponent<Props> = ({
  onSave,
  product,
  tabIndex,
  loading = false,
  withSeries = false,
}) => {
  return (
    <>
      <Box component="span" mr={1}>
        <WithLoading
          loading={loading}
          width={100}
          height={24}
          sx={{ borderRadius: "12px" }}
        >
          <ProductProperyLabel
            tabIndex={tabIndex}
            onSave={onSave}
            manufacturer={product.manufacturer}
            product={product}
            variant={ProductProperyLabelVariant.manufacturer}
          />
        </WithLoading>
      </Box>
      <Box component="span" mr={1}>
        <WithLoading
          loading={loading}
          width={100}
          height={24}
          sx={{ borderRadius: "12px" }}
        >
          <ProductProperyLabel
            brand={product.brand}
            product={product}
            onSave={onSave}
            tabIndex={tabIndex}
            variant={ProductProperyLabelVariant.brand}
          />
        </WithLoading>
      </Box>
      <Box component="span" mr={1}>
        <WithLoading
          loading={loading}
          width={100}
          height={24}
          sx={{ borderRadius: "12px" }}
        >
          <ProductProperyLabel
            onSave={onSave}
            variant={ProductProperyLabelVariant.productType}
            tabIndex={tabIndex}
            productType={product.type}
            product={product}
          />
        </WithLoading>
      </Box>
      {withSeries && (
        <Box component="span" mr={1}>
          <WithLoading
            loading={loading}
            width={100}
            height={24}
            sx={{ borderRadius: "12px" }}
          >
            <ProductProperyLabel
              onSave={onSave}
              variant={ProductProperyLabelVariant.series}
              tabIndex={tabIndex}
              brand={product.brand}
              series={product.series}
              product={product}
            />
          </WithLoading>
        </Box>
      )}
    </>
  );
};
