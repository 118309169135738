import { Add, EditOutlined } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { apiService } from "src/services/apiService";
import { CheckEnLabel } from "src/components/common";

//shared
import {
  ProductTypeAttribute,
  ProductTypeAttributeStringValue,
} from "src/shared/api";

interface Props {
  attribute: ProductTypeAttribute;
  value: ProductTypeAttributeStringValue;
  onResponse: () => any;
}

export const ChangeAttributeValueDialog: React.FunctionComponent<Props> = ({
  attribute,
  value,
  onResponse,
}) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");

  useEffect(() => {
    if (value) {
      setName(value.value);
    }
  }, [value]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    if (name && attribute) {
      apiService
        .changeAttributeValue({
          valueId: value.id,
          value: { value: name.trim() },
        })
        .then(() => {
          onResponse();
          handleClose();
        });
    }
  };

  return (
    <>
      <IconButton size="small" onClick={handleOpen} title="Изменить значение">
        <EditOutlined />
      </IconButton>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>
          Изменение значения "{value.value}" атрибута "{attribute.name}"
        </DialogTitle>
        <DialogContent>
          <TextField
            sx={{ mt: 1 }}
            fullWidth
            value={name}
            label="Название"
            onChange={(e) => setName(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CheckEnLabel>{name}</CheckEnLabel>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Закрыть</Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={!name || !value}
            onClick={handleConfirm}
          >
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
