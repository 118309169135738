import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
} from "@mui/material";
import React, { useState } from "react";
import { BrandSelect } from "src/components/common";
import { routesMap } from "src/routes";

//shared
import { Brand } from "src/shared/api";
import { FCWithChildren } from "src/types/app";

interface Props {
  onSave: (value: Brand) => any;
  title?: React.ReactNode;
  brandId?: number | null;
  manufacturerId?: number | null;
  nullable?: boolean;
  disabled?: boolean;
}

export const BrandSelectDialog: FCWithChildren<Props> = ({
  onSave,
  children,
  brandId,
  manufacturerId,
  nullable = false,
  disabled = false,
  title = "Выбор типа продукта",
}) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<null | Brand>(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setValue(null);
  };

  const handleSave = () => {
    if (value) {
      onSave(value);
    }
    setOpen(false);
  };

  return (
    <>
      {children && //@ts-ignore
        React.cloneElement(children, { onClick: handleOpen })}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>
          <Icon>
            <routesMap.brands.icon />
          </Icon>{" "}
          {title}
        </DialogTitle>
        <DialogContent>
          <Box mt={2}>
            <BrandSelect onSelect={setValue} brandId={brandId} manufacturerId={manufacturerId}/>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Отмена</Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={nullable ? false : !value}
            onClick={handleSave}
          >
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
