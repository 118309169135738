import {
  CallSplitOutlined,
  CommentOutlined,
  LocalOfferOutlined,
  SearchOutlined,
  SettingsOutlined,
  TuneOutlined,
  ViewComfy,
} from "@mui/icons-material";
import {
  ProductTypesPage,
  ProductTypePage,
  AttributesPage,
  ProductsPage,
  Playground,
  ProductPage,
  ManufacturersPage,
  BrandsPage,
  ManufacturerPage,
  BrandPage,
} from "src/components/pages";

//shared
import {
  Brand,
  Manufacturer,
  Product,
  ProductType,
  Series,
} from "src/shared/api";
import { routesMap as configRoutesMap } from "src/shared/config";
import { ReviewsPage } from "./pages/reviews";

const getAppPrefix = (url: string) => url;

export const routesMap = {
  productTypes: {
    path: getAppPrefix("/product-types"),
    getTitle: () => "Типы продукта",
    getRoute: () => getAppPrefix("/product-types"),
    icon: ViewComfy,
  },
  productType: {
    path: getAppPrefix("/product-types/:productTypeId"),
    getTitle: (productType: ProductType) => productType.name,
    getRoute: (id?: number | string) => getAppPrefix(`/product-types/${id}`),
    icon: ViewComfy,
  },
  products: {
    path: getAppPrefix("/products"),
    getTitle: () => "Продукты",
    getRoute: () => getAppPrefix("/products"),
    icon: SearchOutlined,
  },
  product: {
    path: getAppPrefix("/products/:productId"),
    getTitle: (product: Product) => product.title,
    getRoute: (id?: number | string) => getAppPrefix(`/products/${id}`),
  },
  brands: {
    path: getAppPrefix("/brands"),
    getTitle: () => "Бренды",
    getRoute: () => getAppPrefix("/brands"),
    icon: LocalOfferOutlined,
  },
  brand: {
    path: getAppPrefix("/brands/:brandId"),
    getTitle: (brand: Brand) => brand.name,
    getRoute: (id?: number | string) => getAppPrefix(`/brands/${id}`),
    icon: LocalOfferOutlined,
  },
  manufacturers: {
    path: getAppPrefix("/manufacturers"),
    getTitle: () => "Производители",
    getRoute: () => getAppPrefix("/manufacturers"),
    icon: SettingsOutlined,
  },
  manufacturer: {
    path: getAppPrefix("/manufacturers/:manufacturerId"),
    getTitle: (manufacturer: Manufacturer) => manufacturer.name,
    getRoute: (id?: number | string) => getAppPrefix(`/manufacturers/${id}`),
    icon: SettingsOutlined,
  },
  attributes: {
    path: getAppPrefix("/attributes"),
    getTitle: () => "Атрибуты",
    getRoute: () => getAppPrefix("/attributes"),
    icon: TuneOutlined,
  },
  series: {
    path: getAppPrefix("/series"),
    getTitle: () => "Серии",
    getRoute: () => getAppPrefix("/series"),
    icon: CallSplitOutlined,
  },
  seriesSingle: {
    path: getAppPrefix("/attributes"),
    getTitle: (series: Series) => series.name,
    getRoute: (id?: number | string) => getAppPrefix(`/products?series=${id}`),
    icon: CallSplitOutlined,
  },
  reviews: { ...configRoutesMap.reviews, icon: CommentOutlined },
};

export const routes = [
  {
    path: "/",
    getRoute: () => "/",
    Component: ProductsPage,
  },
  {
    path: routesMap.products.path,
    getRoute: routesMap.products.getRoute,
    Component: ProductsPage,
    Icon: routesMap.products?.icon,
    title: routesMap.products.getTitle(),
  },
  {
    path: routesMap.productTypes.path,
    getRoute: routesMap.productTypes.getRoute,
    Component: ProductTypesPage,
    Icon: routesMap.productTypes?.icon,
    title: routesMap.productTypes.getTitle(),
  },
  {
    path: routesMap.productType.path,
    getRoute: routesMap.productType.getRoute,
    Component: ProductTypePage,
  },
  {
    path: routesMap.product.path,
    getRoute: routesMap.product.getRoute,
    Component: ProductPage,
  },
  {
    path: routesMap.brand.path,
    getRoute: routesMap.brand.getRoute,
    Component: BrandPage,
  },
  {
    path: routesMap.attributes.path,
    getRoute: routesMap.attributes.getRoute,
    Component: AttributesPage,
    Icon: routesMap.attributes?.icon,
    title: routesMap.attributes.getTitle(),
  },
  {
    path: routesMap.manufacturer.path,
    getRoute: routesMap.manufacturer.getRoute,
    Component: ManufacturerPage,
  },
  {
    path: routesMap.manufacturers.path,
    getRoute: routesMap.manufacturers.getRoute,
    Component: ManufacturersPage,
    Icon: routesMap.manufacturers?.icon,
    title: routesMap.manufacturers.getTitle(),
  },
  {
    path: routesMap.brands.path,
    getRoute: routesMap.brands.getRoute,
    Component: BrandsPage,
    Icon: routesMap.brands?.icon,
    title: routesMap.brands.getTitle(),
  },
  {
    path: routesMap.reviews.path,
    getRoute: routesMap.reviews.getRoute,
    Component: ReviewsPage,
    Icon: routesMap.reviews?.icon,
    title: routesMap.reviews.getTitle(),
  },
  {
    path: "/playground",
    getRoute: () => "/playground",
    Component: Playground,
  },
];
