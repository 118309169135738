import {
  formatDistance as formatDistanceDateFns,
  format as formatDateFns,
  differenceInHours as differenceInHoursFns,
} from "date-fns";
import locale from "date-fns/locale/ru";

const normalizeDate = (input: string | Date) =>
  typeof input === "string" ? new Date(input) : input;


export const formatDate = (
  date?: Date | string,
  template: string = "yyyy.MM.dd HH:mm:ss"
) =>
  date
    ? formatDateFns(
        typeof date === "string" ? new Date(date) : date,
        template,
        {
          locale,
        }
      )
    : date;

export const formatDistance = (
  date?: Date | string,
  baseDate?: Date | string
) =>
  date && baseDate
    ? formatDistanceDateFns(normalizeDate(date), normalizeDate(baseDate), {
        locale,
      })
    : null;

export const differenceInHours = (
  leftDate?: Date | string | null,
  rightDate?: Date | string| null
) =>
  leftDate && rightDate
    ? differenceInHoursFns(normalizeDate(leftDate), normalizeDate(rightDate))
    : null;
