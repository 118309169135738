import React from "react";
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
  CssBaseline,
} from "@mui/material";
import { BrowserRouter, Route } from "react-router-dom";
import { Routes } from "react-router-dom";
import { MainLayout } from "src/components/common";
import { ThemeProvider as DarModeProvider, useTheme } from "next-themes";
import { routes } from "src/routes";
import { useGetMe } from "src/services/apiService";
import { deepOrange, teal } from "@mui/material/colors";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

const lightTheme = createTheme({
  palette: {
    primary: teal,
    secondary: deepOrange,
  },
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: teal,
    secondary: deepOrange,
    text: {
      primary: "rgb(231, 235, 240)",
      secondary: "rgb(178, 186, 194)",
      disabled: "rgba(255, 255, 255, 0.3)",
    },
    background: {
      default: "#0A1929",
      paper: "rgb(0, 30, 60)",
    },
    divider: "rgb(19, 47, 76)",
  },
});

const Theme = ({ children }: any) => {
  const { theme } = useTheme();
  return (
    <MuiThemeProvider theme={theme === "dark" ? darkTheme : lightTheme}>
      {children}
    </MuiThemeProvider>
  );
};

const ProtectedRoute = ({ user, children, loading }: any) => {
  if (loading) {
    return <>Загрузка...</>;
  }

  if (
    user?.roles?.some((role: string) =>
      [
        "ROLE_SUPREME_LORD",
        "ROLE_ADMIN",
        "ROLE_MANAGER",
        "ROLE_IMAGE_MANAGER",
      ].includes(role)
    )
  )
    return children;

  return <>Вы не авторизованы</>;
};

function App() {
  const meRequest = useGetMe();
  return (
    <BrowserRouter>
      <DarModeProvider>
        <Theme>
          <CssBaseline />
          <MainLayout user={meRequest?.data}>
            <Routes>
              {routes.map((route) => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={
                    <ProtectedRoute
                      user={meRequest.data}
                      loading={meRequest.isLoading}
                    >
                      <route.Component />
                    </ProtectedRoute>
                  }
                />
              ))}

              {/* <Route
              exact
              path="/login"
              component={(props: RouteProps) => (
                <LoginForm
                  redirect={
                    // @ts-ignore
                    props?.location?.state?.from
                  }
                />
              )}
            />
            {routes.map(({ component, ...route }) => (
              <PrivateRoute
                key={route.path}
                exact
                path={route.path}
                component={(props: any) => <MainLayout>{component}</MainLayout>}
              />
            ))} */}
            </Routes>
          </MainLayout>
        </Theme>
      </DarModeProvider>
    </BrowserRouter>
  );
}

export default App;
