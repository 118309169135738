import { Box, Button, Chip, Paper, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import {
  Table,
  ManufacturerSelectDialog,
  WithConfirm,
  Link,
  BrandLabel,
  AliasLabel,
  ManufacturerCreateDialog,
} from "src/components/common";
import { routesMap } from "src/routes";
import {
  apiService,
  useGetManufacturers,
  useGetUndefinedManufacturers,
} from "src/services/apiService";
import { formattingService } from "src/services/formattingService";

//shared
import { usePageTitle, useRouter } from "src/shared/hooks";
import { Alias, Manufacturer, UndefinedManufacturer } from "src/shared/api";

const Brands = () => {
  const pageTitle = usePageTitle();

  useEffect(() => {
    pageTitle.set(routesMap.manufacturers.getTitle());
  }, []);
  const requestManufacturers = useGetManufacturers();

  const manufacturers =
    useMemo(
      () =>
        requestManufacturers.data?.sort((a, b) => (a.name > b.name ? 1 : -1)),
      [requestManufacturers.data]
    ) || [];

  const handleDeleteAlias = (alias: Alias) =>
    apiService.deleteAlias(alias.id).then(requestManufacturers.refetch);

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <ManufacturerCreateDialog onResponse={requestManufacturers.refetch} />
      </Box>
      <Paper sx={{ mt: 2 }}>
        <Table
          withPagination={false}
          loading={requestManufacturers.isLoading}
          totalCount={manufacturers.length}
          data={manufacturers}
          limit={manufacturers.length}
          offset={manufacturers.length}
          headers={[
            {
              title: "Название",
              extract: (item: Manufacturer) => (
                <Link href={routesMap.manufacturer.getRoute(item.id)} blank>
                  {item.name || "Производитель без названия"}
                </Link>
              ),
            },
            {
              title: "Алиасы",
              extract: (item: Manufacturer) => (
                <Box sx={{ mr: 1, mt: 1, display: "flex", flexWrap: "wrap" }}>
                  {item?.aliases?.map((alias) => (
                    <Box key={alias.id} sx={{ mr: 0.5, mb: 0.5 }}>
                      <AliasLabel alias={alias} />
                    </Box>
                  ))}
                </Box>
              ),
            },
            {
              title: "Бренды",
              extract: (item: Manufacturer) => (
                <Box sx={{ mr: 1, mt: 1, display: "flex", flexWrap: "wrap" }}>
                  {item?.brands?.map((brand) => (
                    <Box key={brand.id} sx={{ mr: 0.5, mb: 0.5 }}>
                      <BrandLabel
                        brand={brand}
                        linkIcon
                        onDelete={() =>
                          // @ts-ignore
                          window
                            ?.open(routesMap.brand.getRoute(brand.id), "_blank")
                            ?.focus()
                        }
                      />
                    </Box>
                  ))}
                </Box>
              ),
            },
          ]}
        />
      </Paper>
    </>
  );
};
const Undefined = () => {
  const [loading, setLoading] = useState(false);

  const requestUbdefinedManufacturers = useGetUndefinedManufacturers();

  const manufacturers =
    useMemo(
      () =>
        requestUbdefinedManufacturers.data?.sort((a, b) =>
          a.value > b.value ? 1 : -1
        ),
      [requestUbdefinedManufacturers.data]
    ) || [];

  const handleRefetch = () => {
    requestUbdefinedManufacturers.refetch();
    setLoading(false);
  };

  const handleAddAliasToManufacturer = (
    alias: UndefinedManufacturer,
    manufacturer: Manufacturer | null
  ) => {
    if (manufacturer) {
      setLoading(true);
      apiService
        .addAliasToManufacturer({
          manufacturerId: manufacturer.id,
          alias,
        })
        .then(handleRefetch);
    }
  };

  const handleCreateManufacturer = (item: UndefinedManufacturer) => {
    setLoading(true);
    apiService.createManufacturer({ name: item.value }).then(handleRefetch);
  };

  return (
    <Paper>
      <Table
        withPagination={false}
        loading={requestUbdefinedManufacturers.isLoading}
        totalCount={manufacturers.length}
        data={manufacturers}
        limit={manufacturers.length}
        offset={manufacturers.length}
        headers={[
          {
            title: "Название",
            extract: (item: UndefinedManufacturer) => item.value,
          },
          {
            title: "Источник",
            extract: (item: UndefinedManufacturer) => (
              <div>
                <div>{item.supplier.name}</div>
                <Typography variant="caption">
                  {formattingService.date(item.createTime)}
                </Typography>
              </div>
            ),
          },
          {
            title: "Действия",
            extract: (item: UndefinedManufacturer) => (
              <Box display="flex">
                <ManufacturerSelectDialog
                  title={`Добавление алиаса "${item.value}" к производителю`}
                  onSave={(manufacturer) =>
                    handleAddAliasToManufacturer(item, manufacturer)
                  }
                >
                  <Button size="small" variant="contained" disabled={loading}>
                    Добавить алиас к производителю
                  </Button>
                </ManufacturerSelectDialog>
                <WithConfirm
                  disabled={loading}
                  onConfirm={() => handleCreateManufacturer(item)}
                  confirmTitle={`Создать производителя "${item.value}"`}
                >
                  <Button
                    disabled={loading}
                    size="small"
                    sx={{ ml: 1 }}
                    variant="contained"
                    color="secondary"
                  >
                    Создать нового производителя
                  </Button>
                </WithConfirm>
              </Box>
            ),
          },
        ]}
      />
    </Paper>
  );
};

interface Props {}

export const ManufacturersPage: React.FunctionComponent<Props> = ({}) => {
  const router = useRouter();

  const tab = router.query.get("tab") || "manufacturers";

  return (
    <div>
      <Tabs
        value={tab}
        onChange={(e, value) =>
          router.push(
            `${routesMap.manufacturers.getRoute()}?${new URLSearchParams({
              tab: value,
            }).toString()}`
          )
        }
      >
        <Tab value="manufacturers" label="Производители" />
        <Tab value="undefined" label="Новые" />
      </Tabs>
      <Box mt={2}>
        {tab === "manufacturers" && <Brands />}
        {tab === "undefined" && <Undefined />}
      </Box>
    </div>
  );
};
