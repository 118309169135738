import React, { useState, SyntheticEvent } from "react";
import { Add } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Radio,
  TextField,
} from "@mui/material";
import { apiService, useGetAttributes } from "src/services/apiService";
import { Attributes } from "src/components/common";

//shared
import { ProductType, ProductTypeAttribute } from "src/shared/api";

interface Props {
  productType: ProductType;
  onResponse: () => any;
}

export const AddAttributeToProductTypeDialog: React.FunctionComponent<
  Props
> = ({ productType, onResponse }) => {
  const [open, setOpen] = useState(false);

  const [attribute, setAttribute] = useState<ProductTypeAttribute | null>(null);

  const request = useGetAttributes({ skip: !open });

  const handleOpen = (e: SyntheticEvent) => {
    e.stopPropagation()
    setOpen(true);
    setAttribute(null);
  };

  const handleClose = () => {
    setOpen(false);
    setAttribute(null);
  };

  const handleConfirm = () => {
    if (attribute) {
      apiService
        .addAttributeToProductType({
          productTypeId: productType.id,
          attribute: { id: attribute.id },
        })
        .then(() => {
          onResponse();
          handleClose();
        });
    }
  };

  return (
    <>
      <Fab
        size="small"
        onClick={handleOpen}
        color="secondary"
        title="Добавить атрибут"
      >
        <Add />
      </Fab>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>
          Добавление атрибута к типу продукта "{productType.name}"
        </DialogTitle>
        <DialogContent>
          <TextField
            sx={{ mt: 1 }}
            fullWidth
            value={productType.name}
            label="Тип продукта"
            disabled
          />
          {request.data && (
            <Box mt={2}>
              <Attributes
                onSave={onResponse}
                attributes={request.data}
                itemAction={(item) => (
                  <Radio
                    checked={attribute?.id === item.id}
                    onClick={() => setAttribute(item)}
                  />
                )}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Закрыть</Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={!attribute || !productType}
            onClick={handleConfirm}
          >
            Создать
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
