import React, { useEffect, useState } from "react";
import {
  Close,
  PaletteOutlined,
  PhotoCameraOutlined,
  StarOutlined,
  UploadFileOutlined,
  VisibilityOffOutlined,
} from "@mui/icons-material";
import {
  Alert,
  ButtonBase,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Snackbar,
} from "@mui/material";
import { Box } from "@mui/system";
import {
  addImagesToProduct,
  apiService,
  useGetProductImages,
} from "src/services/apiService";
import { useDropzone } from "react-dropzone";
import { WithLoading } from "src/components/common";

import { clearProductColorImage } from "src/entities/product";

import { Image, Product } from "src/shared/api";

const ImagesList: React.FunctionComponent<{
  images?: Image[];
  product?: Product | null;
  loading?: boolean;
  onClick: (image: Image) => any;
  onChange: () => any;
}> = ({ images, product, onClick, onChange, loading = false }) => {
  const [errors, setErrors] = useState<any>(null);

  const handleUpload = (filesList: any) => {
    const files: any = [];
    if (filesList && product) {
      filesList.forEach((file: any) => files.push(file));
      addImagesToProduct(product.id, files).then((res) => {
        if (res.errors) {
          setErrors(res.errors);
        }
        onChange();
      });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleUpload,
  });

  const defaultImage = images?.find(
    (item) => item.uri === product?.default_image?.uri
  );

  const imagesWithoutDefault =
    images?.filter((item) => item.uri !== defaultImage?.uri) || [];

  return (
    <>
      {errors?.map((error: any) => (
        <Snackbar
          key={error.title}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={Boolean(errors?.length)}
          onClose={() => setErrors(null)}
        >
          <Alert
            onClose={() => setErrors(null)}
            severity="error"
            sx={{ width: "100%" }}
          >
            {error.title}
          </Alert>
        </Snackbar>
      ))}
      <WithLoading loading={loading} width={100} height={125}>
        {defaultImage && (
          <ImagePreview
            onChange={onChange}
            item={defaultImage}
            product={product}
            onClick={() => onClick(defaultImage)}
          />
        )}
        {imagesWithoutDefault
          .sort((a, b) => {
            return a.uri > b.uri ? 1 : -1;
          })
          .map((item, i) => (
            <ImagePreview
              key={item.id}
              onChange={onChange}
              item={item}
              product={product}
              onClick={() => onClick(item)}
            />
          ))}
        <Paper
          sx={{
            m: 0.5,
            width: "100px",
            height: "125px",
          }}
        >
          <ButtonBase
            {...getRootProps({})}
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
            }}
          >
            <>
              <Box sx={{ fontSize: "12px" }}>
                перетащите файл или нажмите для выбора
              </Box>
              <input {...getInputProps()} />

              <UploadFileOutlined sx={{ mt: 1 }} />
            </>
          </ButtonBase>
        </Paper>
      </WithLoading>
    </>
  );
};

const ImagePreview: React.FunctionComponent<{
  item: Image;
  product?: Product | null;
  onClick: () => any;
  onChange: () => any;
}> = ({ item, product, onClick, onChange }) => {
  const handleSetImage = (value: any) => {
    if (product) {
      apiService
        .changeProduct({
          productId: product.id,
          value,
        })
        .then(onChange);
    }
  };

  const handleChangeImage = (image: Image) => {
    if (product && image.id) {
      apiService
        .changeProductImage({
          productId: product.id,
          imageId: image.id,
          image: { ...image, published: !image.published },
        })
        .then(onChange);
    }
  };

  const handleOnClickColorImage = (image: Image) => {
    if (image.uri === product?.color_image?.uri) {
      return clearProductColorImage({ id: product.id }).then(onChange);;
    }

    return handleSetImage({ color_image: image });
  };

  return (
    <Paper
      key={item.uri}
      sx={{
        m: 0.5,
        width: "100px",
        height: "125px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <IconButton
          onClick={() => handleSetImage({ default_image: item })}
          title="Установить как главное изображение"
          sx={{
            padding: "3px",
            opacity: item.uri === product?.default_image?.uri ? 1 : 0.3,
          }}
          color={
            item.uri === product?.default_image?.uri ? "primary" : "default"
          }
        >
          <StarOutlined sx={{ width: "18px", height: "18px" }} />
        </IconButton>
        <IconButton
          onClick={() => handleOnClickColorImage(item)}
          title="Установить как изображение цвета"
          sx={{
            padding: "3px",
            opacity: item.uri === product?.color_image?.uri ? 1 : 0.3,
          }}
          color={item.uri === product?.color_image?.uri ? "primary" : "default"}
        >
          <PaletteOutlined sx={{ width: "18px", height: "18px" }} />
        </IconButton>
        <IconButton
          onClick={() => handleChangeImage(item)}
          title="Скрыть"
          sx={{
            padding: "3px",
            opacity: item?.published === false ? 1 : 0.3,
          }}
          color={item?.published === false ? "error" : "default"}
        >
          <VisibilityOffOutlined sx={{ width: "18px", height: "18px" }} />
        </IconButton>
      </Box>
      <ButtonBase
        onClick={onClick}
        sx={{
          backgroundImage: `url(${item.uri}?h=100)`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "100px",
          height: "100px",
        }}
      ></ButtonBase>
    </Paper>
  );
};
interface Props {
  product?: Product | null;
  onSave?: () => any;
  onOpen?: () => any;
  loading?: boolean;
  size?: number;
  variant?: "preview" | "list";
}

export const ProdcutImage: React.FunctionComponent<Props> = ({
  product,
  onSave,
  onOpen,
  size = 77,
  loading = false,
  variant = "preview",
}) => {
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState<null | Image>(null);

  useEffect(() => {
    if (product) {
      setImage(product.default_image);
    }
  }, [product]);

  const request = useGetProductImages({
    variables: { id: product?.id as number },
    skip: variant === "list" && product ? false : !open || !product,
  });

  const handleOpen = () => {
    setOpen(true);
    if (onOpen) {
      onOpen();
    }
  };

  const handleClose = () => setOpen(false);

  const handleChange = () => {
    request.refetch();
    if (onSave) onSave();
  };

  return (
    <>
      {variant === "preview" && (
        <WithLoading loading={loading} width={size} height={size}>
          <ButtonBase
            onClick={handleOpen}
            tabIndex={-1}
            sx={(theme) => ({
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: "4px",
              width: `${size}px`,
              height: `${size}px`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              backgroundColor: product?.default_image
                ? "#fff"
                : "rgba(255,255,255, 0.3)",
              filter: 0.8,
              "&:hover": {
                opacity: 1,
              },
            })}
          >
            {product?.default_image ? (
              <img
                loading="lazy"
                src={`${product.default_image?.uri}?h=${size}`}
                style={{ maxWidth: `${size}px` }}
              />
            ) : (
              <PhotoCameraOutlined sx={{ opacity: 0.3 }} />
            )}
          </ButtonBase>
        </WithLoading>
      )}
      {variant === "list" && (
        <ImagesList
          loading={request.isLoading}
          onClick={(image) => {
            setOpen(true);
            setImage(image);
          }}
          onChange={handleChange}
          images={request?.data}
          product={product}
        />
      )}

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle
          variant="h5"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {product?.title}
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {request.isLoading ? (
                <CircularProgress />
              ) : (
                image && (
                  <img
                    src={`${image?.uri}?h=570`}
                    style={{ height: "100%", maxHeight: "570px" }}
                  />
                )
              )}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: "center", flexDirection: "column" }}
        >
          <Box
            mt={2}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <ImagesList
              loading={request.isLoading}
              onClick={setImage}
              onChange={handleChange}
              images={request?.data}
              product={product}
            />
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};
