import React from "react";

import {
  ProdcutImage,
  ProductProperties,
  ProductDialog,
  ProductLinks,
  Barcodes,
} from "src/components/common";
import { Box, Typography, Link } from "@mui/material";

//shared
import { Product } from "src/shared/api";

interface Props {
  product: Product;
  disableTabIndex?: boolean;
  loading?: boolean;
  onSave?: () => any;
}

export const ProductPreview: React.FunctionComponent<Props> = ({
  product,
  onSave,
  disableTabIndex,
  loading = false,
}) => {
  const tabIndex = disableTabIndex ? -1 : 0;

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
      }}
    >
      <Box>
        <ProdcutImage
          product={product}
          onSave={onSave}
          loading={loading}
          size={77}
        />
      </Box>
      <Box
        ml={2}
        sx={{
          width: "100%",
        }}
      >
        <div>
          <ProductDialog product={product} onSave={onSave}>
            <Link tabIndex={tabIndex} sx={{ mr: 2, cursor: "pointer" }}>
              {product.title}
            </Link>
          </ProductDialog>
          <Box component="span" sx={{ whiteSpace: "nowrap" }}>
            <ProductLinks product={product} tabIndex={tabIndex} />
          </Box>
        </div>
        <Box>
          <Typography
            variant="caption"
            sx={(theme) => ({ fontSize: theme.typography.pxToRem(12) })}
          >
            <Barcodes
              tabIndex={tabIndex}
              barcodes={product.barcode ? [product.barcode] : []}
              loading={loading}
            />{" "}
            <span>
              {Boolean(product?.characteristics?.length) && (
                <>
                  [
                  {product?.characteristics
                    ?.sort((a, b) => b.attribute.id - a.attribute.id)
                    ?.map((item) => {
                      if (typeof item?.value === "boolean") {
                        return item?.value
                          ? item.attribute.name
                          : `не ${item.attribute.name}`;
                      }
                      //@ts-ignore
                      return item?.value?.value || item?.adapt_value;
                    })
                    ?.join(", ")
                    .toLocaleLowerCase()}
                  ]
                </>
              )}
            </span>
          </Typography>
        </Box>

        <Box sx={{ mt: 0.5, display: "flex" }}>
          <ProductProperties
            loading={loading}
            product={product}
            tabIndex={tabIndex}
            onSave={onSave}
          />
        </Box>
      </Box>
    </Box>
  );
};
