import React, { useEffect } from "react";
import { Box, IconButton, Paper } from "@mui/material";
import {
  Attributes,
  WithLoading,
  AttributeCreateDialog,
  WithConfirm,
} from "src/components/common";
import { apiService, useGetAttributes } from "src/services/apiService";
import { Close } from "@mui/icons-material";

//shared
import { usePageTitle } from "src/shared/hooks";
import { ProductTypeAttribute } from "src/shared/api";

interface Props {}

export const AttributesPage: React.FunctionComponent<Props> = ({}) => {
  const pageTitle = usePageTitle();

  useEffect(() => {
    pageTitle.set("Атрибуты");
  }, []);

  const request = useGetAttributes();

  const handleDelete = (item: ProductTypeAttribute) =>
    apiService.deleteAttribute(item.id).then(request.refetch);

  return (
    <>
      <Paper>
        <Box sx={{ p: 2, mb: 2, display: "flex", justifyContent: "flex-end" }}>
          <AttributeCreateDialog onResponse={request.refetch} />
        </Box>
      </Paper>
      <WithLoading loading={request.isLoading} height={58} rows={5  }>
        {request?.data && (
          <Attributes
            onSave={request.refetch}
            attributes={request?.data}
            itemAction={(item) => (
              <WithConfirm
                confirmTitle={`Удалить атрибут "${item.name}"`}
                onConfirm={() => handleDelete(item)}
              >
                <IconButton
                  size="small"
                  color="error"
                  title={`Удалить атрибут "${item.name}"`}
                >
                  <Close />
                </IconButton>
              </WithConfirm>
            )}
          />
        )}
      </WithLoading>
    </>
  );
};
