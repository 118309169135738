import React, { useEffect, useState } from "react";
import {
  useGetProducts,
  useGetBrand,
  apiService,
  addLogoToBrand,
} from "src/services/apiService";
import {
  AliasLabel,
  CheckEnLabel,
  ManufacturerLabel,
  ManufacturerSelectDialog,
  Products,
  MetaTagData,
} from "src/components/common";
import {
  Box,
  Button,
  ButtonBase,
  Checkbox,
  FormControlLabel,
  Grid,
  Icon,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { routesMap } from "src/routes";
import { Check, UploadFileOutlined } from "@mui/icons-material";
import { useDropzone } from "react-dropzone";

//shared
import { usePageTitle, useRouter } from "src/shared/hooks";
import { MetaTagData as IMetaTagData } from "src/shared/api";

const BrandComponent = () => {
  const router = useRouter();
  const pageTitle = usePageTitle();

  const [name, setName] = useState<null | string>(null);
  const [title, setTitle] = useState<null | string>(null);
  const [description, setDescription] = useState<null | string>(null);
  const [adaptedName, setAdaptedName] = useState<null | string>(null);
  const [highlighted, setHighlighted] = useState<boolean>(false);
  const [metaTagData, setMetaTagData] = useState<IMetaTagData>({
    title: null,
    description: null,
    keywords: null,
  });

  const brandId = router.params.brandId as string;

  const requestBrand = useGetBrand({
    variables: { id: brandId as string },
  });

  useEffect(() => {
    if (requestBrand.data) {
      pageTitle.set(routesMap.brand.getTitle(requestBrand.data));

      if (requestBrand.data.name) {
        setName(requestBrand.data.name);
      }
      if (requestBrand.data.adapted_name) {
        setAdaptedName(requestBrand.data.adapted_name);
      }
      if (requestBrand.data.meta_tag_data) {
        setMetaTagData(requestBrand.data.meta_tag_data);
      }
      if (requestBrand.data.title) {
        setTitle(requestBrand.data.title);
      }
      if (requestBrand.data.description) {
        setDescription(requestBrand.data.description);
      }
      if (typeof requestBrand.data.highlighted === "boolean") {

        setHighlighted(requestBrand.data.highlighted);
      }
    }
  }, [requestBrand.data]);

  const handleSave = () => {
    if (requestBrand.data) {
      handleChange({
        name: name?.trim(),
        adapted_name: adaptedName?.trim(),
        manufacturer: requestBrand.data.manufacturer?.id,
        title: title?.trim(),
        description,
        meta_tag_data: { ...requestBrand.data.meta_tag_data, ...metaTagData },
        highlighted,
      });
    }
  };

  const handleChange = (brand: {
    name?: string | null;
    adapted_name?: string | null;
    manufacturer?: number | null;
    meta_tag_data?: IMetaTagData;
    title?: string | null;
    description?: string | null;
    highlighted?: boolean;
  }) => {
    if (requestBrand.data) {
      apiService
        .changeBrand({
          brandId,
          brand,
        })
        .then(requestBrand.refetch);
    }
  };
  const isChanged = !requestBrand.data
    ? false
    : requestBrand.data?.name !== name ||
      requestBrand.data?.adapted_name !== adaptedName ||
      requestBrand.data?.meta_tag_data?.title !== metaTagData.title ||
      requestBrand.data?.meta_tag_data?.description !==
        metaTagData.description ||
      requestBrand.data?.meta_tag_data?.keywords !== metaTagData.keywords ||
      requestBrand.data?.title !== title ||
      requestBrand.data?.description !== description ||
      requestBrand.data?.highlighted !== highlighted;

  const handleUploadLogo = (filesList: any) => {
    const images = new FormData();
    filesList.forEach((file: any, index: number) =>
      images.append(`file`, file)
    );

    addLogoToBrand(brandId, images).then(requestBrand.refetch);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleUploadLogo,
  });

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={4}>
        <TextField
          disabled={!requestBrand.data?.name}
          fullWidth
          value={name || ""}
          onChange={(e) => setName(e.target.value || null)}
          label="Название"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CheckEnLabel>{name}</CheckEnLabel>
                {requestBrand.data?.name === name && (
                  <Icon color="primary" title="Сохранено">
                    <Check />
                  </Icon>
                )}
              </InputAdornment>
            ),
          }}
        />

        <TextField
          sx={{ mt: 2 }}
          fullWidth
          value={adaptedName || ""}
          onChange={(e) => setAdaptedName(e.target.value || null)}
          label="Адаптированое название"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CheckEnLabel>{adaptedName}</CheckEnLabel>
                {requestBrand.data?.adapted_name === adaptedName && (
                  <Icon color="primary" title="Сохранено">
                    <Check />
                  </Icon>
                )}
              </InputAdornment>
            ),
          }}
        />
        <TextField
          sx={{ mt: 2 }}
          fullWidth
          value={title || ""}
          onChange={(e) => setTitle(e.target.value || null)}
          label="Заголовок страницы"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CheckEnLabel>{title}</CheckEnLabel>
                {requestBrand.data?.title === title && (
                  <Icon color="primary" title="Сохранено">
                    <Check />
                  </Icon>
                )}
              </InputAdornment>
            ),
          }}
        />
        <TextField
          sx={{ mt: 2 }}
          fullWidth
          value={description || ""}
          onChange={(e) => setDescription(e.target.value || null)}
          label="Описание"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CheckEnLabel>{description}</CheckEnLabel>
                {requestBrand.data?.description === description && (
                  <Icon color="primary" title="Сохранено">
                    <Check />
                  </Icon>
                )}
              </InputAdornment>
            ),
          }}
        />

        <FormControlLabel
          sx={{ mt: 2 }}
          control={<Checkbox checked={highlighted} />}
          label="Выделенный бренд"
          onChange={() => setHighlighted(!highlighted)}
        />
        <Box mt={2}>
          <MetaTagData
            data={metaTagData}
            onChange={(key, value) =>
              setMetaTagData({ ...metaTagData, [key]: value })
            }
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={8}>
        <Box
          sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            color="secondary"
            onClick={handleSave}
            variant="contained"
            disabled={!isChanged}
          >
            Сохранить
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center">
          <Paper
            style={{
              marginRight: "16px",
              width: "125px",
              height: "125px",
              backgroundSize: "contain",
              backgroundImage: requestBrand.data?.logoBase64
                ? `url(${requestBrand.data?.logoBase64})`
                : "unset",
            }}
          >
            <ButtonBase
              {...getRootProps({})}
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "100%",
              }}
            >
              <>
                <input {...getInputProps()} />
                {!requestBrand.data?.logoBase64 && <UploadFileOutlined />}
              </>
            </ButtonBase>
          </Paper>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5">Поизводитель</Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap", mt: 1 }}>
          {requestBrand.data?.manufacturer && (
            <ManufacturerSelectDialog
              title={`Производитель для бренда "${requestBrand.data?.name}"`}
              manufacturerId={requestBrand.data.manufacturer?.id}
              onSave={(manufacturer) =>
                handleChange({
                  ...requestBrand.data,
                  manufacturer: manufacturer.id,
                })
              }
            >
              <ManufacturerLabel
                manufacturer={requestBrand.data.manufacturer}
                linkIcon
                onDelete={() =>
                  // @ts-ignore
                  window
                    ?.open(
                      routesMap.manufacturer.getRoute(
                        requestBrand.data?.manufacturer?.id
                      ),
                      "_blank"
                    )
                    ?.focus()
                }
              />
            </ManufacturerSelectDialog>
          )}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5">Алиасы</Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          {requestBrand.data?.aliases?.map((alias) => (
            <Box sx={{ mr: 1, mt: 1 }} key={alias.id}>
              <AliasLabel alias={alias} onDelete={requestBrand.refetch} />
            </Box>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};

interface Props {}

export const BrandPage: React.FunctionComponent<Props> = ({}) => {
  const router = useRouter();

  const requestProducts = useGetProducts({
    variables: { brand: router.params.brandId },
  });

  const products = requestProducts.data?.data || [];

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <BrandComponent />
      </Grid>
      <Grid item xs={12}>
        <Products
          products={products}
          limit={products.length}
          offset={0}
          loading={requestProducts.isLoading}
          onSave={requestProducts.refetch}
        />
      </Grid>
    </Grid>
  );
};
