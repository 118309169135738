import { Add } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { apiService } from "src/services/apiService";
import { FCWithChildren } from "src/types/app";
import { CheckEnLabel } from "src/components/common";

//shared
import { ProductTypeAttribute } from "src/shared/api";

interface Props {
  attribute: ProductTypeAttribute;
  onResponse?: () => any;
}

export const AddValueToAttributeDialog: FCWithChildren<Props> = ({
  attribute,
  onResponse,
  children,
}) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");

  const handleOpen = () => {
    setOpen(true);
    setName("");
  };

  const handleClose = () => {
    setOpen(false);
    setName("");
  };

  const handleConfirm = () => {
    if (name && attribute) {
      apiService
        .addValueToAttribute({
          attributeId: attribute.id,
          value: { value: name.trim() },
        })
        .then(() => {
          if (onResponse) {
            onResponse();
          }
          handleClose();
        });
    }
  };

  return (
    <>
      {children ? (
        //@ts-ignore
        React.cloneElement(children, { onClick: handleOpen })
      ) : (
        <Fab
          size="small"
          onClick={handleOpen}
          color="secondary"
          title="Добавить значение"
        >
          <Add />
        </Fab>
      )}
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>
          Добавление значения к атрибуту "{attribute.name}"
        </DialogTitle>
        <DialogContent>
          <TextField
            sx={{ mt: 1 }}
            fullWidth
            value={name}
            label="Название"
            onChange={(e) => setName(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CheckEnLabel>{name}</CheckEnLabel>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Закрыть</Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={!name || !attribute}
            onClick={handleConfirm}
          >
            Создать
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
