import React from "react";
import { Box, Checkbox, CircularProgress, Pagination } from "@mui/material";
import { grey } from "@mui/material/colors";

import { CodeSelect } from "./CodeSelect";
//entities
import { ProductPreview } from "src/entities/product";
import { useGetRewiews } from "src/entities/review";

//shared
import { Review as IReview, addQueryParams } from "src/shared/api";
import { routesMap } from "src/shared/config";
import { usePageTitle, useRouter } from "src/shared/hooks";

import { formatDate } from "src/shared/libs/date";
import { changeReview } from "src/entities/review";
import { UserPreview } from "src/entities/user/ui/UserPreview";
import { Review } from "src/features/review";

const LIMIT = 50;

interface Props {}

export const ReviewsPage: React.FunctionComponent<Props> = ({}) => {
  usePageTitle(routesMap.reviews.getTitle());

  const router = useRouter();

  const query = {
    page: router.query.get("page")
      ? parseInt(router.query.get("page") as string, 10)
      : 1,
    products: router.query.get("products") || null,
  };

  const request = useGetRewiews({
    variables: {
      query: { ...query, limit: LIMIT, offset: LIMIT * (query.page - 1) },
    },
  });

  return (
    <div>
      <div>
        <CodeSelect
          codes={query?.products?.split(",")}
          onChange={(values) =>
            router.push(
              addQueryParams({
                href: routesMap.reviews.getRoute(),
                query: { ...query, page: 1, products: values.join(",") },
              })
            )
          }
        />
      </div>
      <Box>
        {request.data?.data?.map((review) => (
          <Box sx={{ mb: 2 }} key={review.id}>
            <Review
              review={review}
              onChange={request.mutate}
              product={<ProductPreview product={review.product} />}
            />
          </Box>
        ))}
        {typeof request.data?.total_count === "number" && (
          <Box>
            <Pagination
              count={request.data?.total_count || 0}
              page={query.page}
              onChange={(e, page) =>
                router.push(
                  addQueryParams({
                    href: routesMap.reviews.getRoute(),
                    query: { ...query, page },
                  })
                )
              }
            />
          </Box>
        )}
      </Box>
    </div>
  );
};
