import React, { useEffect, useState } from "react";

import { MuiChipsInput } from "mui-chips-input";
import { Box, Button, Typography } from "@mui/material";

interface Props {
  onChange: (values: string[]) => any;
  codes?: string[];
}

export const CodeSelect: React.FunctionComponent<Props> = ({
  onChange,
  codes,
}) => {
  const [value, setValue] = useState<string[]>([]);
  useEffect(() => {
    if (codes) {
      setValue(codes);
    }
  }, [codes]);

  const handleOnDelete = (codeForDelete: string) => {
    const nextValues = value.filter((item) => item !== codeForDelete);
    setValue(nextValues);
    onChange(nextValues);
  };

  const handleOnChange = (values: string[]) => {
    let items = values;

    if (values.length === 1) {
      items = values[0]?.replace(",", " ").replace(";", " ").split(" ");
    }

    const nextValues = Array.from(new Set(items.filter((item) => item))).map(
      (item) => item.replace(/\D/g, "")
    );

    setValue(nextValues);
    onChange(nextValues);
  };

  return (
    <>
      <MuiChipsInput
        value={value}
        onChange={handleOnChange}
        label="Фильтр по кодам"
        placeholder=""
      />
      <Box sx={{ mt: 1 }}>
        <Typography>
          Количество кодов: {value.length}{" "}
          {Boolean(value.length) && (
            <Button
              size="small"
              color="primary"
              onClick={() => {
                setValue([]);
                onChange([]);
              }}
            >
              очистить
            </Button>
          )}
        </Typography>
      </Box>
    </>
  );
};
