import { Grid, TextField } from "@mui/material";
import React from "react";
import { ManufacturerSelect, BrandSelect } from "src/components/common";

//shared
import { Brand, Manufacturer } from "src/shared/api";

interface Props {
  manufacturerId?: number | null;
  brandId?: number | null;
  barcode?: number | string | null;
  onChange: (params: {
    manufacturer?: number | null;
    brand?: number | null;
    barcode?: number | string | null;
  }) => any;
}

export const ProductsFilters: React.FunctionComponent<Props> = ({
  manufacturerId,
  brandId,
  barcode,
  onChange,
}) => {
  const handleManufacturer = (value: Manufacturer | null) =>
    onChange({ manufacturer: value?.id || null });

  const handleBrand = (value: Brand | null) =>
    onChange({ brand: value?.id || null });

  const handleBarcode = (e: any) => onChange({ barcode: e?.target?.value });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3} lg={2}>
        <TextField
          fullWidth
          label="Штрих-код"
          variant="outlined"
          value={barcode || barcode}
          onChange={handleBarcode}
        />
      </Grid>
      <Grid item xs={12} md={3} lg={2}>
        <ManufacturerSelect
          manufacturerId={manufacturerId}
          onSelect={handleManufacturer}
        />
      </Grid>
      <Grid item xs={12} md={3} lg={2}>
        <BrandSelect
          manufacturerId={manufacturerId}
          brandId={brandId}
          onSelect={handleBrand}
        />
      </Grid>
    </Grid>
  );
};
