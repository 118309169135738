import React, { useEffect, useState } from "react";
import { useGetProduct } from "src/services/apiService";
import { Product } from "src/components/common";
import { Box, Typography } from "@mui/material";

import { usePageTitle, useRouter } from "src/shared/hooks";
import { useGetRewiews } from "src/entities/review";
import { Review } from "src/features/review";

interface Props {}

export const ProductPage: React.FunctionComponent<Props> = ({}) => {
  const router = useRouter();
  const pageTitle = usePageTitle();

  const request = useGetProduct({
    variables: { id: router.params.productId as string },
  });

  const productReviewsRequest = useGetRewiews({
    variables: { query: { products: router.params.productId as string } },
  });

  useEffect(() => {
    if (request.data) {
      pageTitle.set(request.data.title);
    }
  }, [request.data]);

  return (
    <>
      <Product
        product={request.data}
        loading={request.isLoading}
        onSave={request.refetch}
        template={({ title, content, actions }) => (
          <>
            <Box>
              <Typography variant="h5">{title}</Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              {actions}
            </Box>
            <Box>{content}</Box>
          </>
        )}
      />
      <Box sx={{ mt: 4 }}>
        <Box>
          <Typography variant="h5">Отзывы</Typography>
        </Box>
        {productReviewsRequest.data?.data?.map((review) => (
          <Box sx={{ mb: 2 }} key={review.id}>
            <Review review={review} onChange={request.mutate} />
          </Box>
        ))}
      </Box>
    </>
  );
};
