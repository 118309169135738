import { LinkOutlined } from "@mui/icons-material";
import { Chip } from "@mui/material";
import React from "react";
import { routesMap } from "src/routes";

//shared
import { Manufacturer } from "src/shared/api";



interface Props {
  manufacturer?: Manufacturer | null;
  clickable?: boolean;
  linkIcon?: boolean;
  tabIndex?: number;
  onDelete?: () => any;
  deleteIcon?: () => any;
  onClick?: () => any;
}

export const ManufacturerLabel: React.FunctionComponent<Props> = ({
  manufacturer,
  tabIndex = 0,
  onDelete,
  onClick,
  linkIcon,
}) => {
  return (
    <Chip
      title="Производитель"
      sx={{ opacity: manufacturer ? 1 : 0.8 }}
      color="default"
      variant={manufacturer ? "filled" : "outlined"}
      icon={<routesMap.manufacturers.icon />}
      size="small"
      clickable={onClick ? true : undefined}
      tabIndex={tabIndex}
      label={
        manufacturer ? manufacturer?.name || "Производитель без названия" : null
      }
      onClick={onClick}
      onDelete={onDelete}
      deleteIcon={linkIcon ? <LinkOutlined /> : undefined}
    />
  );
};
