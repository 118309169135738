import React, { ReactNode } from "react";
import { Attribute } from "src/components/common";
import { Paper, Box, Grid } from "@mui/material";

//shared
import { ProductTypeAttribute } from "src/shared/api";

interface Props {
  attributes: ProductTypeAttribute[];
  itemAction?: (attribute: ProductTypeAttribute) => ReactNode;
  onSave: () => any;
}

export const Attributes: React.FunctionComponent<Props> = ({
  attributes,
  itemAction,
  onSave,
}) => {
  return (
    <Grid>
      {attributes
        ?.sort((a, b) => (a.name > b.name ? 1 : -1))
        ?.map((attribute: ProductTypeAttribute) => (
          <Paper
            sx={{ mb: 1, display: "flex", alignItems: "center" }}
            key={attribute.id}
          >
            <Attribute attribute={attribute} onSave={onSave} />
            {itemAction && <Box mr={1}>{itemAction(attribute)}</Box>}
          </Paper>
        ))}
    </Grid>
  );
};
