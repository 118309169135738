import { useEffect, useState } from "react";

export const usePageTitle = (initialTitle?: string) => {
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (initialTitle) {
      set(initialTitle);
    }
  }, [initialTitle]);

  // const titleNode =   document?.getElementsByTagName("title")?.[0]?.innerHTML?.toString();
  const targetNode = document?.getElementsByTagName("title")?.[0];

  // Options for the observer (which mutations to observe)
  const config = { childList: true };

  // Callback function to execute when mutations are observed
  const callback = function (mutationList: any, observer: any) {
    for (const mutation of mutationList) {
      if (mutation.type === "childList") {
        setTitle(targetNode?.innerHTML?.toString());
      }
    }
  };

  const observer = new MutationObserver(callback);

  useEffect(() => {
    observer.observe(targetNode, config);
    return () => observer.disconnect();
  }, []);

  const set = (nextTitle: string) => {
    document.title = nextTitle;
  };

  return {
    set,
    title,
  };
};
