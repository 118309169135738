import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import {
  ProductProperyLabel,
  ChangeAttributeValueDialog,
  AddValueToAttributeDialog,
  ChangeAttributeDialog,
  ProductProperyLabelVariant,
  WithConfirm,
} from "src/components/common";
import { routesMap } from "src/routes";
import { Close, ExpandMore } from "@mui/icons-material";
import { formattingService } from "src/services/formattingService";
import { apiService } from "src/services/apiService";

//shared
import { ProductTypeAttribute, ProductTypeAttributeStringValue, ProductTypeAttributeType } from "src/shared/api";

interface Props {
  attribute: ProductTypeAttribute;
  disableEdit?: boolean;
  onSave: () => any;
}

export const Attribute: React.FunctionComponent<Props> = ({
  attribute,
  onSave,
  disableEdit = false,
}) => {
  const handleDeleteValue = (item: ProductTypeAttributeStringValue) =>
    apiService.deleteAttributeValue(item.id).then(onSave);

  return (
    <Accordion
      sx={{ width: "100%", boxShadow: "unset" }}
      expanded={
        attribute.type === ProductTypeAttributeType.string ||
        ("types" in attribute && attribute?.types?.length)
          ? undefined
          : false
      }
    >
      <AccordionSummary
        expandIcon={
          (attribute.type === ProductTypeAttributeType.string ||
            ("types" in attribute && attribute?.types?.length)) && (
            <ExpandMore />
          )
        }
      >
        <div>
          <Typography variant="caption" component="span"></Typography>{" "}
          <Typography variant="body1" component="span">
            {attribute.name} <Chip label={attribute.type} size="small" />
          </Typography>{" "}
          <ChangeAttributeDialog
            attribute={attribute}
            onResponse={onSave}
            disabled={disableEdit}
          />{" "}
          {Boolean(attribute?.types?.length) && (
            <Chip
              size="small"
              icon={<routesMap.productType.icon />}
              label={`${attribute?.types?.length} ${
                attribute?.types?.length &&
                formattingService.declOfNum([
                  "тип продукта",
                  "типа продукта",
                  "типов продукта",
                ])(attribute?.types?.length)
              }
                `}
            />
          )}{" "}
          {"list_possible_values" in attribute &&
            Boolean(attribute?.list_possible_values?.length) && (
              <Chip
                size="small"
                label={
                  <>
                    {attribute?.list_possible_values?.length}{" "}
                    {"list_possible_values" in attribute &&
                      attribute?.list_possible_values?.length &&
                      formattingService.declOfNum([
                        "значение",
                        "значения",
                        "значений",
                      ])(attribute?.list_possible_values?.length)}
                  </>
                }
              />
            )}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          {Boolean(attribute?.types?.length) && (
            <Box>
              {attribute?.types
                ?.sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((type) => (
                  <Box p={0.25} display="inline-block" key={type.id}>
                    <ProductProperyLabel
                      productType={type}
                      variant={ProductProperyLabelVariant.productType}
                    />
                  </Box>
                ))}
            </Box>
          )}
          {"list_possible_values" in attribute && (
            <List dense disablePadding>
              {attribute?.list_possible_values?.map((value, index) => (
                <ListItem
                  key={value?.id}
                  disablePadding
                  dense
                  divider={Boolean(
                    attribute.list_possible_values?.length &&
                      attribute.list_possible_values?.length - 1 > index
                  )}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <Typography variant="caption">{value?.id}</Typography>
                    </ListItemIcon>
                    <ListItemText primary={value.value.toString()} />
                    <ListItemSecondaryAction sx={{ display: "flex" }}>
                      <ChangeAttributeValueDialog
                        value={value}
                        attribute={attribute}
                        onResponse={onSave}
                      />
                      <WithConfirm
                        disabled={disableEdit}
                        confirmTitle={`Удалить значение  "${value.value.toString()}"`}
                        onConfirm={() => handleDeleteValue(value)}
                      >
                        <IconButton
                          disabled={disableEdit}
                          size="small"
                          color="error"
                          title={`Удалить значение  "${value.value.toString()}"`}
                        >
                          <Close />
                        </IconButton>
                      </WithConfirm>
                    </ListItemSecondaryAction>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          )}
        </div>
      </AccordionDetails>
      {attribute.type === ProductTypeAttributeType.string && (
        <>
          <Divider />
          <AccordionActions>
            <AddValueToAttributeDialog
              attribute={attribute}
              onResponse={onSave}
            />
          </AccordionActions>
        </>
      )}
    </Accordion>
  );
};
