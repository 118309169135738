import { Box } from "@mui/material";
import { red } from "@mui/material/colors";
import { fontWeight } from "@mui/system";
import React from "react";
import { formattingService } from "src/services/formattingService";

interface Props {
  children: string | null;
}

export const CheckEnLabel: React.FunctionComponent<Props> = ({ children }) => {
  const isEn = formattingService.checkForLatin(children);
  if (!children || !isEn) return null;

  return (
    <Box
      component="span"
      title="Содержит латиницу"
      sx={{
        display:'inline-block',
        textAlign: 'center',
        width: "24px",
        heigth: "8px",
        color: red[50],
        backgroundColor: red[500],
        borderRadius: '4px',
        fontWeight: 900
      }}
    >
      en
    </Box>
  );
};
